import { BaseFrontPage } from '@components';
import {
  FrontSidebarBanners,
  FrontSidebarMenu,
  FrontSidebarNotifications,
  FrontSidebarStatistics,
} from '@components/FrontSidebarBox';
import { NewsList } from '@components/News/NewsList';

export const NewsPage = () => {
  return (
    <BaseFrontPage
      disableScrollToTop
      sidebarItems={[
        <FrontSidebarMenu title="Dražby a veřejné soutěže" />,
        <FrontSidebarNotifications title="Upozornění na Váš e-mail" />,
        <FrontSidebarStatistics title="Exdražby v číslech" />,
        <FrontSidebarBanners />,
      ]}
    >
      <NewsList />
    </BaseFrontPage>
  );
};
