import React, { useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import classNames from 'classnames';
import { Col, Form, Row } from 'react-bootstrap';
import { FormikErrors, FormikValues } from 'formik';

import { useEnumApi } from '@api/enum';
import { useAuctionsApi } from '@api/auctions';
import { getRequestDateFormat } from '@helpers/datetime';
import { AuctionFieldsEnabled, TimesEnumType } from '@types';
import { useAuctionCategoriesApi } from '@api/auctionCategories';
import { BasePreloader, Button, ControlFeedback, DatePickerInput, FormGroup, Select } from '@components';

import inputIcoCalendar from '@assets/images/input-ico-calendar.svg';
import { Link } from 'react-router-dom';
import * as routes from '@routes';
import { usePageStore } from '@stores';
import { toast } from 'react-hot-toast';

interface Props {
  id?: string;
  values: FormikValues;
  isSubmitting: boolean;
  errors: FormikErrors<FormikValues>;
  fieldsEnabled?: AuctionFieldsEnabled;
  auctionType: 'auction' | 'auction_public' | 'public_competition';
  getTranslation: (key: string) => string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
}

const DetailsSection: React.FC<Props> = (props) => {
  const enumApi = useEnumApi();
  const auctionsApi = useAuctionsApi();
  const pageState = usePageStore();
  const auctionCategoriesApi = useAuctionCategoriesApi();
  const [generalTimes, setGeneralTimes] = useState<TimesEnumType>();
  const urlToCopy = window.location.origin + pageState.getPagePath(
    props.values.auctionType === 'auction' ? routes.front.AUCTION_DETAIL : props.values.auctionType === 'public_competition' ? routes.front.AUCTION_PUBLIC_COMPETITION_DETAIL : routes.front.AUCTION_PUBLIC_DETAIL,
    {
      ':id': props.id || '',
    },
  );

  const hoursOptions = (generalTimes?.hours || []).map((i) => ({
    label: i,
    value: parseInt(i).toString(),
  }));

  const minuteOptions = (generalTimes?.minutes || []).map((i) => ({
    label: i,
    value: parseInt(i).toString(),
  }));

  React.useEffect(() => {
    loadEnums().then();
    return () => {
      auctionsApi.cancelAllRequests();
      auctionCategoriesApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadEnums = async () => {
    try {
      const res = await enumApi.getTimes();
      setGeneralTimes(res.data.data);
    } catch (err) {
      if (enumApi.isCancel(err)) {
        return;
      }
    }
  };

  const handleDateChange = (prop: string, value: Date | Date[] | null) => {
    if (value !== null) {
      const inputValue = value as Date;
      props.setFieldValue(prop, !!inputValue ? getRequestDateFormat(inputValue) : '');
    }
  };

  return (
    <div>
      <div className="pl-2">
        <div className="flex flex-column">
          <h2 className="f-weight-300 f-size-25">{props.getTranslation('tab_detail_title')}</h2>
          {props.id && (
            <Link
              className="d-inline-flex align-items-center f-weight-400 f-size-12"
              to={urlToCopy}
              target="_blank"
            >
              {urlToCopy}
            </Link>
          )}

          <Button onClick={() => {
            navigator.clipboard.writeText(urlToCopy).then(() => {
              toast.success('URL adresa nakopírována', { duration: 4000 });
            });
          }} className="ml-3">
            {props.getTranslation('tab_detail_btn_copy_url')}
          </Button>
        </div>

        <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => props.handleSubmit(e)} className="mt-40">
          <div className="responsive-table-content">
            <div className="pt-2">
              {props.values.status !== 'adjourned' && (
                <>
                  <Form.Group className="f-inline-group">
                    <Form.Label className="f-inline-label">
                      {props.getTranslation('tab_detail_label_start_dt')}
                    </Form.Label>
                    <div
                      className="f-inline-control d-flex align-items-center datetime-feedback auction-datetime-picker">
                      <div className="position-relative">
                        <DatePickerInput
                          readOnly={!_.get(props.fieldsEnabled, 'startDt', true)}
                          name="startDt"
                          className={classNames([
                            'form-control',
                            'w-max-140',
                            { 'is-invalid': !!props.errors.startDt },
                          ])}
                          calendarIcon={<img src={inputIcoCalendar} alt="ico" />}
                          clearIcon={null}
                          onChange={(val) => handleDateChange('startDt', val)}
                          value={!!props.values.startDt ? moment(props.values.startDt || '').toDate() : undefined}
                        />
                        {typeof props.errors.startDt === 'string' && (
                          <div className="auction-date-error">
                            <ControlFeedback type="invalid">{props.errors.startDt}</ControlFeedback>
                          </div>
                        )}
                      </div>
                      <div className="auction-time-picker">
                        <span className="f-size-12 w-weight-400 ml-3 mr-3 time-at">v</span>
                        <div data-test-id="admin-auction-startDtHours">
                          <Select
                            size="md"
                            isSearchable
                            name="startDtHour"
                            minWidth={100}
                            options={hoursOptions}
                            dataTestId="startDtHours"
                            isDisabled={!_.get(props.fieldsEnabled, 'startDt', true)}
                            onChange={(val) => props.setFieldValue('startDtHour', val?.value || '0')}
                            value={hoursOptions.find((o) => o.value === (props.values.startDtHour || 0).toString())}
                          />
                        </div>
                        <span className="f-size-12 w-weight-400 ml-3 mr-3">:</span>
                        <div data-test-id="admin-auction-startDtMinutes">
                          <Select
                            size="md"
                            isSearchable
                            minWidth={100}
                            name="startDtMinutes"
                            options={minuteOptions}
                            dataTestId="startDtMinutes"
                            isDisabled={!_.get(props.fieldsEnabled, 'startDt', true)}
                            onChange={(val) => props.setFieldValue('startDtMinutes', val?.value || '0')}
                            value={minuteOptions.find((o) => o.value === (props.values.startDtMinutes || 0).toString())}
                          />
                        </div>
                        <span className="f-size-12 w-weight-400 ml-3 mr-3">hod</span>
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group className="f-inline-group">
                    <Form.Label className="f-inline-label text-left">
                      {props.getTranslation('tab_detail_label_end_dt')}
                    </Form.Label>
                    <div
                      className="f-inline-control d-flex align-items-center datetime-feedback auction-datetime-picker">
                      <div className="position-relative">
                        <DatePickerInput
                          name="endDt"
                          readOnly={!_.get(props.fieldsEnabled, 'endDt', true)}
                          className={classNames(['form-control', 'w-max-140', { 'is-invalid': !!props.errors.endDt }])}
                          calendarIcon={<img src={inputIcoCalendar} alt="ico" />}
                          clearIcon={null}
                          onChange={(val) => handleDateChange('endDt', val)}
                          value={!!props.values.endDt ? moment(props.values.endDt || '').toDate() : undefined}
                        />
                        {!!props.errors.endDt && (
                          <div className="auction-date-error">
                            <ControlFeedback type="invalid">{props.errors.endDt as string}</ControlFeedback>
                          </div>
                        )}
                      </div>
                      <div className="auction-time-picker">
                        <span className="f-size-12 w-weight-400 ml-3 mr-3 time-at">v</span>
                        <div data-test-id="admin-auction-endDtHours">
                          <Select
                            size="md"
                            isSearchable
                            name="endDtHour"
                            minWidth={100}
                            options={hoursOptions}
                            dataTestId="endDtHours"
                            isDisabled={!_.get(props.fieldsEnabled, 'endDt', true)}
                            onChange={(val) => props.setFieldValue('endDtHour', val?.value || '0')}
                            value={hoursOptions.find((o) => o.value === (props.values.endDtHour || 0).toString())}
                          />
                        </div>
                        <span className="f-size-12 w-weight-400 ml-3 mr-3">:</span>
                        <div data-test-id="admin-auction-endDtMinutes">
                          <Select
                            size="md"
                            isSearchable
                            minWidth={100}
                            name="endDtMinutes"
                            options={minuteOptions}
                            dataTestId="endDtMinutes"
                            isDisabled={!_.get(props.fieldsEnabled, 'endDt', true)}
                            onChange={(val) => props.setFieldValue('endDtMinutes', val?.value || '0')}
                            value={minuteOptions.find((o) => o.value === (props.values.endDtMinutes || 0).toString())}
                          />
                        </div>
                        <span className="f-size-12 w-weight-400 ml-3 mr-3">hod</span>
                      </div>
                    </div>
                  </Form.Group>
                </>
              )}
              <div className="d-flex align-items-center mb-35">
                <FormGroup
                  required
                  thousandSeparator
                  name="minimalBid"
                  label={props.getTranslation('tab_detail_label_minimal_bid')}
                  className="mt-0 mb-0"
                  labelClassName="text-left"
                  controlClassName="w-max-205"
                  readOnly={!_.get(props.fieldsEnabled, 'minimalBid', true)}
                  error={props.errors.minimalBid as string}
                  value={props.values.minimalBid}
                  onValueChange={(val) => props.setFieldValue('minimalBid', val.value)}
                />
                <span className="f-size-12 f-weight-400 ml-2">Kč</span>
              </div>
              <div className="d-flex align-items-center mb-35">
                <FormGroup
                  required
                  thousandSeparator
                  name="minimalThrow"
                  label={props.getTranslation('tab_detail_label_minimal_throw')}
                  className="mt-0 mb-0"
                  labelClassName="text-left"
                  controlClassName="w-max-205"
                  error={props.errors.minimalThrow as string}
                  value={props.values.minimalThrow}
                  readOnly={!_.get(props.fieldsEnabled, 'minimalThrow', true)}
                  onValueChange={(val) => props.setFieldValue('minimalThrow', val.value)}
                />
                <span className="f-size-12 f-weight-400 ml-2">Kč</span>
              </div>
              {props.values.auctionType === 'auction' && (
                <div className="d-flex align-items-center mb-35">
                  <FormGroup
                    thousandSeparator
                    name="minPurchasePrice"
                    label={props.getTranslation('tab_detail_label_min_purchase_price')}
                    className="mt-0 mb-0"
                    labelClassName="text-left"
                    controlClassName="w-max-205"
                    error={props.errors.minPurchasePrice as string}
                    value={props.values.minPurchasePrice}
                    readOnly={!_.get(props.fieldsEnabled, 'minPurchasePrice', true)}
                    onValueChange={(val) => props.setFieldValue('minPurchasePrice', val.value)}
                  />
                  <span className="f-size-12 f-weight-400 ml-2">Kč</span>
                </div>
              )}
              <div className="d-flex align-items-center mb-35">
                <FormGroup
                  required
                  thousandSeparator
                  type="number"
                  name="estimatedPrice"
                  label={props.getTranslation('tab_detail_label_estimated_price')}
                  className="mt-0 mb-0"
                  labelClassName="text-left mb-0"
                  controlClassName="w-max-205"
                  error={props.errors.estimatedPrice as string}
                  value={props.values.estimatedPrice}
                  readOnly={!_.get(props.fieldsEnabled, 'estimatedPrice', true)}
                  onValueChange={(val) => props.setFieldValue('estimatedPrice', val.value)}
                />
                <span className="f-size-12 f-weight-400 ml-2">Kč</span>
              </div>
              <div className="d-flex align-items-center mb-35">
                <FormGroup
                  thousandSeparator
                  type="number"
                  name="cautionDeposit"
                  label={props.getTranslation('tab_detail_label_caution_deposit')}
                  className="mt-0 mb-0"
                  labelClassName="text-left mb-0"
                  controlClassName="w-max-205"
                  error={props.errors.cautionDeposit as string}
                  value={props.values.cautionDeposit}
                  onChange={props.handleChange}
                  readOnly={!_.get(props.fieldsEnabled, 'cautionDeposit', true)}
                  onValueChange={(val) => props.setFieldValue('cautionDeposit', val.value)}
                />
                <span className="f-size-12 f-weight-400 ml-2">Kč</span>
              </div>
              <FormGroup
                name="webUrl"
                label={props.getTranslation('tab_detail_label_web_url')}
                labelClassName="text-left"
                error={props.errors.webUrl as string}
                value={props.values.webUrl}
                readOnly={!_.get(props.fieldsEnabled, 'webUrl', true)}
                onChange={props.handleChange}
              />
            </div>
          </div>
          <div>
            <p className="f-size-12 w-weight-400 text-color-gray-2">{props.getTranslation('tab_detail_help_text')}</p>
          </div>
          {!props.id && (
            <Row>
              <Col xs={12} className="mt-4 text-right">
                {!props.isSubmitting ? (
                  <Button type="submit">
                    {!!props.id
                      ? props.getTranslation('tab_detail_btn_save')
                      : props.getTranslation('tab_detail_btn_next')}
                  </Button>
                ) : (
                  <BasePreloader size={29} className="d-inline-block" />
                )}
              </Col>
            </Row>
          )}
        </Form>
      </div>
    </div>
  );
};

export default DetailsSection;
