import React, { useEffect, useState } from 'react';

import { useApplicationApi } from '@api/application';
import BaseFrontSidebarBox from './BaseFrontSidebarBox';

import statistics01 from '@assets/images/front/sidebar/statistics-01.svg';
import statistics02 from '@assets/images/front/sidebar/statistics-02.svg';
import statistics03 from '@assets/images/front/sidebar/statistics-03.svg';
import statistics04 from '@assets/images/front/sidebar/statistics-04.svg';
import { AppStatistics } from '@types';
import { getBaseNumberFormat, getCurrencyFormat } from '@helpers/formats';

interface Props {
  title: string;
}

const FrontSidebarStatistics: React.FC<Props> = (props) => {
  const applicationApi = useApplicationApi();
  const [stats, setStats] = useState<AppStatistics | undefined>();

  useEffect(() => {
    loadStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadStats = async () => {
    try {
      const res = await applicationApi.getAppStatistics();
      setStats(res.data);
    } catch (err) {
      if (applicationApi.isCancel(err)) {
        return;
      }
    }
  };

  if (!stats) {
    return null;
  }

  return (
    <BaseFrontSidebarBox title={props.title} className="sidebar-statistics">
      <div className="statistics-row">
        <div className="statistics-icon">
          <div className="icon-content">
            <img src={statistics01} alt="icon" />
          </div>
        </div>
        <div className="statistics-text">
          Bylo zrealizováno přes
          <br />
          <span className="f-weight-700">{getBaseNumberFormat(stats.totalAuctions)} dražeb a veřejných soutěží</span>
        </div>
      </div>
      <div className="statistics-row">
        <div className="statistics-icon">
          <div className="icon-content">
            <img src={statistics02} alt="icon" />
          </div>
        </div>
        <div className="statistics-text">
          Draží u nás
          <br />
          <span className="f-weight-700">{getBaseNumberFormat(stats.totalAuctioneers)} dražebníků</span>
        </div>
      </div>
      <div className="statistics-row">
        <div className="statistics-icon">
          <div className="icon-content">
            <img src={statistics03} alt="icon" />
          </div>
        </div>
        <div className="statistics-text">
          Máme necelých <span className="f-weight-700">{getBaseNumberFormat(stats.totalBuyers)}</span> registrovaných
          dražitelů
        </div>
      </div>
      <div className="statistics-row">
        <div className="statistics-icon">
          <div className="icon-content">
            <img src={statistics04} alt="icon" />
          </div>
        </div>
        <div className="statistics-text">
          Nejdražší položka vydražená
          <br />
          za <span className="f-weight-700">{getCurrencyFormat(stats.priciest)}</span>
        </div>
      </div>
    </BaseFrontSidebarBox>
  );
};

export default FrontSidebarStatistics;
