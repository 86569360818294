import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import * as routes from '@routes';
import { usePageStore } from '@stores';
import OAuthCSAS from './pages/oauth/OAuthCSAS';

const OAuthRoutes: React.FC = () => {
  const pageState = usePageStore();
  return (
    <Routes>
      <Route path={pageState.getChildPagePath(routes.oauth.CSAS, routes.auth.BASE)} element={<OAuthCSAS />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default OAuthRoutes;
