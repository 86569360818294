import React from 'react';

import { BasePreloader, Button, Visible } from '@components';
import { closeMessage, confirmMessage } from '@helpers/messages';
import { useInformedConsentApi } from '@api/informedConsent';
import { useAuctionInformationApi } from '@api/auctionInformation';
import { PermissionGroup, UserPermission, AuctionInformationResponse, AuctionInformedConsent } from '@types';

import InformationForm from './InformationForm';
import InformationTable from './InformationTable';
import InformedConsentForm from './InformedConsentForm';
import InformedConsentsTable from './InformedConsentsTable';

import plusIco from '@assets/images/plus-ico.svg';
import plusIcoWhite from '@assets/images/plus-ico-white.svg';

interface Props {
  id: string;
  auctionType: 'auction' | 'auction_public' | 'public_competition';
  showActionsChange: (val: boolean) => void;
  auctionStatus: string;
}

const InformationSection: React.FC<Props> = (props) => {
  const informedConsentApi = useInformedConsentApi();
  const auctionInformationApi = useAuctionInformationApi();
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [showInformationForm, setShowInformationForm] = React.useState<string | undefined>(undefined);
  const [informationItemEdit, setInformationItemEdit] = React.useState<AuctionInformationResponse | undefined>(
    undefined,
  );
  const [showConsentForm, setShowConsentForm] = React.useState(false);
  const [consentItemEdit, setConsentItemEdit] = React.useState<AuctionInformedConsent | undefined>(undefined);
  const [informedConsents, setInformedConsents] = React.useState<AuctionInformedConsent[]>([]);
  const [auctionInformation, setAuctionInformation] = React.useState<AuctionInformationResponse[]>([]);

  React.useEffect(() => {
    loadData();
    return () => {
      props.showActionsChange(true);
      auctionInformationApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    try {
      const resInformation = await auctionInformationApi.getAdminList(props.id);
      const resInformedConsents = await informedConsentApi.getInformedConsents(props.id);
      setAuctionInformation(resInformation.data.data);
      setInformedConsents(resInformedConsents.data.data);
      setLoaded(true);
    } catch (err) {
      if (auctionInformationApi.isCancel(err)) {
        return;
      }
      console.error(err);
    }
  };

  const handleAddInformationButtonClick = (formType: string) => {
    props.showActionsChange(false);
    setInformationItemEdit(undefined);
    setShowInformationForm(formType);
  };

  const handleAddConsentButtonClick = () => {
    setShowConsentForm(true);
    setConsentItemEdit(undefined);
    props.showActionsChange(false);
  };

  const handleConsentEditClick = (item: AuctionInformedConsent) => {
    setConsentItemEdit(item);
    setShowConsentForm(true);
    props.showActionsChange(false);
  };

  const handleInformationFormClose = (item?: AuctionInformationResponse) => {
    if (!!item) {
      props.showActionsChange(false);
      setInformationItemEdit(item);
      setShowInformationForm(item.category);
      return;
    }
    props.showActionsChange(true);
    setShowInformationForm(undefined);
    setInformationItemEdit(undefined);
    setLoaded(false);
    loadData();
  };

  const handleConsentFormClose = () => {
    props.showActionsChange(true);
    setConsentItemEdit(undefined);
    setShowConsentForm(false);
    setLoaded(false);
    loadData();
  };

  const handleInformationDeleteClick = (item: AuctionInformationResponse) => {
    confirmMessage({
      title: 'Potvrzení',
      text: 'Opravdu si přejete odebrat tuto položku?',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return auctionInformationApi.deleteInformation(props.id, item.id).catch(() => closeMessage());
      },
    }).then((res) => {
      if (!!res.value && (res.value.status === 200 || res.value.status === 204)) {
        loadData();
      }
    });
  };

  const handleInformedConsentDeleteClick = (item: AuctionInformedConsent) => {
    confirmMessage({
      title: 'Potvrzení',
      text: 'Opravdu si přejete odebrat tuto položku?',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        if (!!item.id) {
          return informedConsentApi.delete(props.id, item.id).catch(() => closeMessage());
        }
      },
    }).then((res) => {
      if (!!res.value && (res.value.status === 200 || res.value.status === 204)) {
        loadData();
      }
    });
  };

  const handleEditClick = (item: AuctionInformationResponse) => {
    props.showActionsChange(false);
    setInformationItemEdit(item);
    setShowInformationForm(item.category);
  };

  const renderSection = () => {
    if (!!showInformationForm) {
      return (
        <InformationForm
          id={props.id}
          item={informationItemEdit}
          formType={showInformationForm}
          auctionType={props.auctionType}
          onClose={handleInformationFormClose}
        />
      );
    }

    if (showConsentForm) {
      return <InformedConsentForm id={props.id} item={consentItemEdit} onClose={handleConsentFormClose} />;
    }

    return (
      <div>
        <div className="mt-4">
          <Visible permissionName={UserPermission.canCreate} permissionGroupName={PermissionGroup.auctionInformation}>
            <Button onClick={() => handleAddInformationButtonClick('a')}>
              <img src={plusIco} alt="ico" className="mr-2 hover-hide" />
              <img src={plusIcoWhite} alt="ico" className="mr-2 hover-show" />
              Přidat
            </Button>
          </Visible>
          <InformationTable
            type="a"
            title="Informace typ A - (v průběhu a na začátku)"
            data={auctionInformation.filter((i) => i.category === 'a')}
            onEditClick={(item) => handleEditClick(item)}
            onDeleteClick={(item) => handleInformationDeleteClick(item)}
          />
        </div>

        <div className="mt-4">
          <Visible permissionName={UserPermission.canCreate} permissionGroupName={PermissionGroup.auctionInformation}>
            <Button onClick={() => handleAddInformationButtonClick('b')}>
              <img src={plusIco} alt="ico" className="mr-2 hover-hide" />
              <img src={plusIcoWhite} alt="ico" className="mr-2 hover-show" />
              Přidat
            </Button>
          </Visible>
          <InformationTable
            type="b"
            title="Informace typ B - (na konci)"
            data={auctionInformation.filter((i) => i.category === 'b')}
            onEditClick={(item) => handleEditClick(item)}
            onDeleteClick={(item) => handleInformationDeleteClick(item)}
          />
        </div>

        <div className="mt-4">
          <InformationTable
            type="c"
            title="Informace typ C - (automatické možnosti na konci vydraženo/nevydraženo)"
            data={auctionInformation.filter((i) => i.category === 'c')}
            onEditClick={(item) => handleEditClick(item)}
          />
        </div>

        <div className="mt-4">
          {props.auctionStatus === 'prepared' && (
            <Visible permissionName={UserPermission.canCreate} permissionGroupName={PermissionGroup.auctionInformation}>
              <Button onClick={() => handleAddConsentButtonClick()}>
                <img src={plusIco} alt="ico" className="mr-2 hover-hide" />
                <img src={plusIcoWhite} alt="ico" className="mr-2 hover-show" />
                Přidat informovaný souhlas
              </Button>
            </Visible>
          )}
          <InformedConsentsTable
            title="Informovaný souhlas"
            data={informedConsents}
            onEditClick={handleConsentEditClick}
            onDeleteClick={handleInformedConsentDeleteClick}
            auctionStatus={props.auctionStatus}
          />
        </div>
      </div>
    );
  };

  if (!loaded) {
    return (
      <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
        <BasePreloader />
      </div>
    );
  }

  return <div>{renderSection()}</div>;
};

export default InformationSection;
