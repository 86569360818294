export const getDaysTranslation = (count: number): string => {
  if (Math.abs(count) === 1) {
    return 'den';
  }
  if (Math.abs(count) > 0 && Math.abs(count) < 5) {
    return 'dny';
  }
  return 'dní';
};

export const getHoursTranslation = (count: number): string => {
  if (count === 1) {
    return 'hodina';
  }
  if (count > 0 && count < 5) {
    return 'hodiny';
  }
  return 'hodin';
};

export const getMinutesTranslation = (count: number): string => {
  if (count === 1) {
    return 'minuta';
  }
  if (count > 0 && count < 5) {
    return 'minuty';
  }
  return 'minut';
};

export const getSecondsTranslation = (count: number): string => {
  if (count === 1) {
    return 'sekunda';
  }
  if (count > 0 && count < 5) {
    return 'sekundy';
  }
  return 'sekund';
};

const getPreparedAuctionsTranslation = (count: number): string => {
  if (count === 1) {
    return 'připravovaná dražba nebo veřejná soutěž';
  }
  if (count > 0 && count < 5) {
    return 'připravované dražby a veřejné soutěže';
  }
  return 'připravovaných dražeb a veřejných soutěží';
};

const getOngoingAuctionsTranslation = (count: number): string => {
  if (count === 1) {
    return 'probíhající dražba, aukce nebo veřejná soutěž';
  }
  if (count > 0 && count < 5) {
    return 'probíhající dražby a veřejné soutěže';
  }
  return 'probíhajících dražeb a veřejných soutěží';
};
const getEndedAuctionsTranslation = (count: number): string => {
  if (count === 1) {
    return 'ukončená dražba nebo veřejná soutěž';
  }
  if (count > 0 && count < 5) {
    return 'ukončené dražby a veřejné soutěže';
  }
  return 'ukončených dražeb a veřejných soutěží';
};

export const getAuctionsTranslation = (status: string | undefined, count: number) => {
  if (status === 'prepared' || status === undefined) {
    return getPreparedAuctionsTranslation(count);
  } else if (status === 'ongoing') {
    return getOngoingAuctionsTranslation(count);
  }
  return getEndedAuctionsTranslation(count);
};

export const getFoundTranslation = (count: number) => {
  if (count === 1) {
    return 'Nalezena';
  }
  if (count > 0 && count < 5) {
    return 'Nalezeny';
  }
  return 'Nalezeno';
};
