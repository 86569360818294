import React from 'react';
import ReactDOM from 'react-dom/client'; // Import from 'react-dom/client'
import Modal from 'react-modal';
import moment from 'moment-timezone';
import numeral from 'numeral';
import toast, { ToastBar, Toaster } from 'react-hot-toast';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import 'moment/locale/cs';

import App from './App';
import config from '@config';
import * as serviceWorker from './serviceWorker';

moment.tz.setDefault('Europe/Prague');

numeral.register('locale', 'cs', {
  delimiters: {
    thousands: ' ',
    decimal: '.',
  },
  abbreviations: {
    thousand: 'tis.',
    million: 'mil.',
    billion: 'bil.',
    trillion: 'trl.',
  },
  ordinal: (number) => {
    const rest = number % 10;
    return ~~((number % 100) / 10) === 1 ? 'tý' : rest === 1 ? 'ní' : rest === 2 ? 'hý' : rest === 3 ? 'tí' : 'tý';
  },
  currency: {
    symbol: 'Kč',
  },
});

numeral.locale('cs');

Modal.setAppElement('#root');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // <React.StrictMode>
  <GoogleReCaptchaProvider reCaptchaKey={config.reCaptchaKey}>
    <App />

    <Toaster
      position="top-right"
      reverseOrder={false}
      toastOptions={{
        className: 'toast',
        success: {
          duration: 5000,
          style: {
            background: '#fafdfa',
            color: '#6fbb77',
            border: '1px solid #e1f1e5',
            boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.05)',
            borderRadius: '0',
            display: 'flex',
            flexDirection: 'row',
            padding: '15px',
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '21px',
            wordWrap: 'break-word',
            position: 'relative',
          },
        },
        error: {
          duration: 5000,
          style: {
            background: '#fdfafa',
            color: '#b88282',
            border: '1px solid #f1e1e1',
            boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.05)',
            borderRadius: '0',
            display: 'flex',
            flexDirection: 'row',
            padding: '15px',
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '21px',
            wordWrap: 'break-word',
            position: 'relative',
          },
          iconTheme: {
            primary: '#b88282',
            secondary: 'white',
          },
        },
      }}
    >
      {(t) => {
        return (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                {icon}
                {message}
                {t.type !== 'loading' && (
                  <div onClick={() => toast.dismiss(t.id)} style={{ cursor: 'pointer', padding: '4px' }}>
                    <svg width="12" height="12" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0)">
                        <path
                          d="M8 6.72933L5.22833 3.993L7.96167 1.23233L6.72933 0L3.992 2.77267L1.222 0.0383333L0 1.26033L2.77367 4.007L0.0383333 6.778L1.26033 8L4.006 5.22733L6.76767 7.96167L8 6.72933Z"
                          fill={t.type === 'success' ? '#6fbb77' : '#b88282'}
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect width="8" height="8" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                )}
              </div>
            )}
          </ToastBar>
        );
      }}
    </Toaster>
  </GoogleReCaptchaProvider>
  // </React.StrictMode>
);

serviceWorker.unregister();
