import React from 'react';
import classNames from 'classnames';
import HamburgerMenu from 'react-hamburger-menu';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import * as routes from '@routes';
import MobileMenuItems from './MobileMenuItems';
import MobileLoggedUser from './MobileLoggedUser';
import { MobileMenuAuctions } from './MobileMenuAuctions';
import { useAuthStore, usePageStore, useAuthFrontStore } from '@stores';

import icoList from '@assets/images/front/header/color-ico-list.svg';
import heartIco from '@assets/images/front/icons/heart-color-20.svg';
import lockColor16 from '@assets/images/front/icons/lock-color-16.svg';
import hammerColor22 from '@assets/images/front/icons/hammer-color-22.svg';
import documentColor21 from '@assets/images/front/icons/document-color-21.svg';

interface Props {
  onLoginClick: () => void;
}

const MobileMenu: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openMobileMenu, setOpenMobileMenu] = React.useState(false);
  const authState = useAuthStore();
  const authFrontState = useAuthFrontStore();
  const pageState = usePageStore();

  React.useEffect(() => {
    setOpenMobileMenu(false);
  }, [location.pathname, location.search]);

  const handleLoginClick = (e: React.MouseEvent) => {
    e.preventDefault();
    props.onLoginClick();
  };

  return (
    <>
      <div className={classNames(['front-hamburger-menu', { 'menu-active': openMobileMenu }])}>
        <div className="hamburger-menu-ico">
          <HamburgerMenu
            isOpen={openMobileMenu}
            menuClicked={() => setOpenMobileMenu(!openMobileMenu)}
            color={'linear-gradient(#62CEA3, #3D8CBB)'}
            width={22}
            height={16}
            strokeWidth={2}
            animationDuration={0.5}
          />
        </div>
        <div className="menu-content">
          <div className="menu-inner">
            <MobileMenuAuctions />
            <div className="block-separator" />
            <MobileMenuItems />
            <div className="block-separator" />
            <div className="block-bottom">
              <Link
                to={
                  authState.isLogged
                    ? pageState.getPagePath(routes.admin.BASE)
                    : pageState.getPagePath(routes.auth.LOGIN)
                }
                className="block-item"
              >
                <span>
                  <img src={hammerColor22} alt="ico" />
                </span>
                Pro dražebníky
              </Link>
              {!authFrontState.isLogged ? (
                <>
                  <a href="/" className="block-item" onClick={handleLoginClick}>
                    <span>
                      <img src={documentColor21} alt="ico" />
                    </span>
                    Registrace
                  </a>
                  <a href="/" className="block-item" onClick={handleLoginClick}>
                    <span>
                      <img src={lockColor16} alt="ico" />
                    </span>
                    Přihlášení
                  </a>
                </>
              ) : (
                <>
                  <Link
                    to="/"
                    className="block-item"
                    onClick={(event) => {
                      event.preventDefault();
                      navigate(pageState.getPagePath(routes.front.ACCOUNT_FAVOURITES));
                    }}
                  >
                    <span>
                      <img src={heartIco} alt="ico" />
                    </span>
                    Oblíbené
                  </Link>
                  {authFrontState.user?.role !== 'ROLE_USER' && (
                    <Link to={pageState.getPagePath(routes.front.ACCOUNT_AUCTIONS)} className="block-item">
                      <span>
                        <img src={icoList} alt="ico" />
                      </span>
                      Moje dražby a veřejné soutěže
                    </Link>
                  )}

                  <MobileLoggedUser onLogout={() => setOpenMobileMenu(false)} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileMenu;
