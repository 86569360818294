import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';

import * as routes from '@routes';
import Tabs from './components/Tabs';
import { useAuctionsApi } from '@api/auctions';
import { urlSearchStore, usePageStore } from '@stores';
import { closeMessage, confirmMessage } from '@helpers/messages';
import { BaseAccountPage, BasePreloader, AuctionTile, Pagination } from '@components';
import { AuctionInformationsSections } from '../AccountAuctionInformations/AccountAuctionInformations';
import { AccountAuctionsSections, AuctionCountResponse, AuctionStatus, UserAuctionResponse } from '@types';

import alertIco from '@assets/images/front/account/alert.svg';

let auctionsTimeout: number | undefined = undefined;

const AccountAuctionsPage: React.FC = () => {
  const perPage = 15;
  const navigate = useNavigate();
  const location = useLocation();
  const auctionsApi = useAuctionsApi();
  const pageState = usePageStore();
  const [totalItems, setTotalItems] = useState(0);
  const defaultQuery = React.useMemo(
    () => ({
      s: withDefault(StringParam, AccountAuctionsSections.prepared),
      page: withDefault(NumberParam, 1),
    }),
    []
  );
  const [query, setQuery] = useQueryParams(defaultQuery);
  const [items, setItems] = React.useState<Array<UserAuctionResponse>>([]);
  const [loaded, setLoaded] = React.useState(false);
  const [counts, setCount] = React.useState<AuctionCountResponse[]>([]);

  const getActiveSection = (section: string): AccountAuctionsSections => {
    const currentSection = section as AccountAuctionsSections;
    return currentSection in AccountAuctionsSections ? currentSection : AccountAuctionsSections.ongoing;
  };

  const tabs = [
    {
      status: 'připravované',
      text: 'Připravované dražby/aukce/soutěže',
      value: AccountAuctionsSections.prepared,
      badge: counts.find((c) => c.status === AccountAuctionsSections.prepared)?.count,
    },
    {
      status: 'probíhající',
      text: 'Probíhající dražby/aukce/soutěže',
      value: AccountAuctionsSections.ongoing,
      badge: counts.find((c) => c.status === AccountAuctionsSections.ongoing)?.count,
    },
    {
      status: 'ukončené',
      text: 'Ukončené dražby/aukce/soutěže',
      value: AccountAuctionsSections.ended,
      badge: counts.find((c) => c.status === AccountAuctionsSections.ended)?.count,
    },
    {
      status: 'předražek',
      text: 'Předražky',
      value: AccountAuctionsSections.overcharge,
      badge: counts.find((c) => c.status === AccountAuctionsSections.overcharge)?.count,
    },
  ];

  React.useEffect(() => {
    loadData();
    return () => {
      auctionsApi.cancelAllRequests();
      if (!!auctionsTimeout) {
        clearTimeout(auctionsTimeout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  React.useEffect(() => {
    urlSearchStore.setState({ frontAccountAuctions: location.search });
  }, [location.search]);

  const loadData = async () => {
    setLoaded(false);
    await loadAuctions();
    setLoaded(true);
  };

  const loadAuctions = async () => {
    if (!!auctionsTimeout) {
      clearTimeout(auctionsTimeout);
    }
    try {
      const response = await auctionsApi.getUserAuctions({
        perPage,
        status: query.s,
        page: query.page,
      });
      setItems(response.data.data);
      setTotalItems(response.data.total || 0);
      setCount((await auctionsApi.getUserAuctionsCount()).data.data);
      auctionsTimeout = window.setTimeout(() => loadAuctions(), 10000);
    } catch (err) {
      if (!err.response) {
        return;
      }
      console.error(err);
    }
  };

  const handleItemDelete = ({ auction }: UserAuctionResponse) => {
    confirmMessage({
      title: 'Potvrzení',
      confirmButtonText: 'Opustit',
      text: 'Opravdu si přejete opustit tuto dražbu?',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return auctionsApi.leaveAuction(auction.id).catch(() => closeMessage());
      },
    }).then((res) => {
      if (!!res.value && (res.value.status === 200 || res.value.status === 204)) {
        loadAuctions();
      }
    });
  };

  return (
    <BaseAccountPage page={routes.front.ACCOUNT_AUCTIONS}>
      <Tabs
        value={getActiveSection(query.s)}
        items={tabs}
        onClick={(tab) => setQuery({ s: tab.value, page: 1 }, 'push')}
      />
      {getActiveSection(query.s) === AccountAuctionsSections.prepared && (
        <div className="component-front-auctions-list">
          <div className="list-items">
            {loaded ? (
              items
                .filter((i) => i.auction.parentStatus === AuctionStatus.prepared)
                .map((item, index) => (
                  <AuctionTile
                    isVerbose
                    auction={item.auction}
                    canLogout={item.canLogout}
                    securityPaid={item.securityPaid}
                    key={`${index}-${item.uniqueCode}`}
                    auctionSecurity={item.auctionSecurity}
                    authorizationState={item.authorizationState}
                    auctionSecurityTranslation={item.auctionSecurityTranslation}
                    primaryActionText={item.auction.translations?.auction_prepared || 'auction_prepared'}
                    onPrimaryActionClick={() =>
                      navigate(
                        pageState.getPagePath(routes.front.ACCOUNT_AUCTIONS_INFORMATIONS, {
                          ':id': item.auction.id,
                        })
                      )
                    }
                    onDelete={() => handleItemDelete(item)}
                  />
                ))
            ) : (
              <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
                <BasePreloader />
              </div>
            )}
          </div>
          {loaded && items.length < 1 && (
            <div className="auctions-empty">
              <img src={alertIco} alt="ico" className="item-ico" />
              Nemáte žádné připravované dražby/aukce/soutěže
            </div>
          )}
        </div>
      )}
      {getActiveSection(query.s) === AccountAuctionsSections.ongoing && (
        <div className="component-front-auctions-list">
          <div className="list-items">
            {loaded ? (
              items.map((item, index) => (
                <AuctionTile
                  isVerbose
                  auction={item.auction}
                  canLogout={item.canLogout}
                  securityPaid={item.securityPaid}
                  key={`${index}-${item.uniqueCode}`}
                  auctionSecurity={item.auctionSecurity}
                  auctionSecurityTranslation={item.auctionSecurityTranslation}
                  authorizationState={item.authorizationState}
                  primaryActionText={item.auction.translations?.auction_process || 'auction_process'}
                  onPrimaryActionClick={() =>
                    navigate(
                      `${pageState.getPagePath(routes.front.ACCOUNT_AUCTIONS_INFORMATIONS, {
                        ':id': item.auction.id,
                      })}?s=${AuctionInformationsSections.process}`
                    )
                  }
                  onDelete={() => handleItemDelete(item)}
                />
              ))
            ) : (
              <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
                <BasePreloader />
              </div>
            )}
          </div>
          {loaded && items.length < 1 && (
            <div className="auctions-empty">
              <img src={alertIco} alt="ico" className="item-ico" />
              Nemáte žádné probíhající dražby/aukce
            </div>
          )}
        </div>
      )}
      {getActiveSection(query.s) === AccountAuctionsSections.ended && (
        <div className="component-front-auctions-list">
          <div className="list-items">
            {loaded ? (
              items.map((item, index) => (
                <AuctionTile
                  isVerbose
                  auction={item.auction}
                  canLogout={item.canLogout}
                  securityPaid={item.securityPaid}
                  key={`${index}-${item.uniqueCode}`}
                  auctionSecurity={item.auctionSecurity}
                  authorizationState={item.authorizationState}
                  auctionSecurityTranslation={item.auctionSecurityTranslation}
                  primaryActionText={item.auction.translations?.auction_result || 'auction_result'}
                  onPrimaryActionClick={() =>
                    navigate(
                      `${pageState.getPagePath(routes.front.ACCOUNT_AUCTIONS_INFORMATIONS, {
                        ':id': item.auction.id,
                      })}?s=${AuctionInformationsSections.result}`
                    )
                  }
                  onDelete={() => handleItemDelete(item)}
                />
              ))
            ) : (
              <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
                <BasePreloader />
              </div>
            )}
          </div>
          {loaded && items.length < 1 && (
            <div className="auctions-empty">
              <img src={alertIco} alt="ico" className="item-ico" />
              Nemáte žádné ukončené dražby/aukce
            </div>
          )}
        </div>
      )}
      {getActiveSection(query.s) === AccountAuctionsSections.overcharge && (
        <div className="component-front-auctions-list">
          <div className="list-items">
            {loaded ? (
              items.map((item, index) => (
                <AuctionTile
                  isVerbose
                  auction={item.auction}
                  canLogout={item.canLogout}
                  securityPaid={item.securityPaid}
                  key={`${index}-${item.uniqueCode}`}
                  auctionSecurity={item.auctionSecurity}
                  authorizationState={item.authorizationState}
                  auctionSecurityTranslation={item.auctionSecurityTranslation}
                  primaryActionText={item.auction.translations?.auction_result || 'auction_result'}
                  onPrimaryActionClick={() =>
                    navigate(
                      `${pageState.getPagePath(routes.front.ACCOUNT_AUCTIONS_INFORMATIONS, {
                        ':id': item.auction.id,
                      })}?s=${AuctionInformationsSections.result}`
                    )
                  }
                  onDelete={() => handleItemDelete(item)}
                />
              ))
            ) : (
              <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
                <BasePreloader />
              </div>
            )}
          </div>
          {loaded && items.length < 1 && (
            <div className="auctions-empty">
              <img src={alertIco} alt="ico" className="item-ico" />
              Nemáte žádné předražky
            </div>
          )}
        </div>
      )}

      {loaded && totalItems > perPage && (
        <div className="mt-2">
          <Pagination
            page={query.page}
            perPage={perPage}
            totalRows={totalItems}
            onPageChange={(page) => setQuery({ ...query, page: page }, 'push')}
          />
        </div>
      )}
    </BaseAccountPage>
  );
};

export default AccountAuctionsPage;
