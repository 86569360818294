import React from 'react';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { union, get } from 'lodash';
import { OnChangeValue } from 'react-select';
import { Col, Form, Row } from 'react-bootstrap';

import icoZoomColor15 from '@assets/images/front/icons/zoom-color-15.svg';
import icoZoomWhite15 from '@assets/images/front/icons/zoom-white-15.svg';
import showFiltersIcon from '@assets/images/front/icons/show-filters.svg';
import showFiltersIconActive from '@assets/images/front/icons/show-filters-active.svg';

import { useAuctionsApi } from '@api/auctions';
import { useLocationApi } from '@api/location';
import { useAuctionCategoriesApi } from '@api/auctionCategories';
import BasePreloader from '@components/BasePreloader/BasePreloader';
import FrontTabsMenu from '@components/FrontTabsMenu/FrontTabsMenu';
import { FormGroup, Button, MapComponent, Select } from '@components';
import { getBaseNumberFormat, getCurrencyFormat } from '@helpers/formats';
import { getAuctionsTranslation, getFoundTranslation } from '@helpers/translation';
import {
  AuctionType,
  District,
  Region,
  WebAuctionsFilter,
  WebCategories,
  AuctionStatus,
  AuctionMapResponse,
  DistrictPart,
} from '@types';
import { useAuctionsListWebsocket } from '@websocket';
import { useWindowFocus } from '@hooks';

interface Props {
  auctions: AuctionMapResponse[];
  auctionsLoaded: boolean;
  withMap: boolean;
  hideType?: boolean;
  query?: any;
  auctioneer?: string;
  itemsCount: number;
  hasToggler?: boolean;
  mobileToggle?: boolean;
  showPreloader?: boolean;
  closeWithSubmit?: boolean;
  status?: string;
  onSearch: (filterProps: WebAuctionsFilter, submit?: boolean) => void;
}

interface TypeSelectOptionType {
  value: string;
  label: string;
}

const FrontMainFilter: React.FC<Props> = (props) => {
  const locationApi = useLocationApi();
  const auctionsApi = useAuctionsApi();
  const auctionCategoriesApi = useAuctionCategoriesApi();
  const [regions, setRegions] = React.useState<Array<Region>>([]);
  const [districts, setDistricts] = React.useState<Array<District>>([]);
  const [districtsParts, setDistrictsParts] = React.useState<Array<DistrictPart>>([]);
  const [showExtendedFilters, setShowExtendedFilters] = React.useState(false);
  const [filterMenuActive, setMenuItemActive] = React.useState('0');
  const [mainCategories, setMainCategories] = React.useState<WebCategories[]>([]);
  const [subCategories, setSubCategories] = React.useState<WebCategories[]>([]);
  const [openFilters, setOpenFilters] = React.useState(!props.hasToggler);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [auctionTypesData, setAuctionTypesData] = React.useState([] as Array<AuctionType>);
  const initialValue = {
    search: get<string>(props.query, 'title', ''),
    priceTo: get<string>(props.query, 'priceTo', ''),
    priceFrom: get<string>(props.query, 'priceFrom', ''),
    regions: get<string[]>(props.query, 'regions', []),
    districts: get<string[]>(props.query, 'districts', []),
    districtsParts: get<string[]>(props.query, 'districtsParts', []),
    subCategories: get<string[]>(props.query, 'subCategories', []),
    auctionType: get<string>(props.query, 'auctionType', ''),
    auctioneer: get<string>(props.query, 'auctioneer', ''),
  };

  useAuctionsListWebsocket({
    onMessage: async () => {
      const menuItem = props.query?.mainCategory || '0';
      await loadMainCategories();
      if (menuItem !== '0') {
        await loadSubCategories(menuItem);
      }
    },
  });

  useWindowFocus({
    onFocus: async () => {
      const menuItem = props.query?.mainCategory || '0';
      await loadMainCategories();
      if (menuItem !== '0') {
        await loadSubCategories(menuItem);
      }
    },
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValue,
    onSubmit: () => handleSearch({ submit: false }),
  });

  const displayDistricts = districts.filter((district) => {
    return ~formik.values.regions.indexOf((district.region?.id || 0).toString());
  });

  const displayDistrictsParts = districtsParts.filter((districtPart) => {
    return ~formik.values.districts.indexOf((districtPart.district?.id || 0).toString());
  });

  React.useEffect(() => {
    loadRegions();
    loadDistricts();
    loadDistrictsParts();
    loadMainCategories();
    loadAuctionTypes();
    return () => {
      locationApi.cancelAllRequests();
      auctionCategoriesApi.cancelAllRequests();
      auctionsApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.status]);

  React.useEffect(() => {
    const menuItem = props.query?.mainCategory || '0';
    setMenuItemActive(menuItem);
    if (menuItem !== '0') {
      loadSubCategories(menuItem);
    } else {
      setSubCategories([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.query?.mainCategory]);

  const handleExtendedFiltersClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowExtendedFilters(!showExtendedFilters);
  };

  const loadRegions = async () => {
    try {
      const response = await locationApi.getRegions();
      setRegions(response.data.data);
    } catch (err) {
      if (locationApi.isCancel(err)) {
        return;
      }
    }
  };

  const loadDistricts = async () => {
    try {
      const response = await locationApi.getDistricts();
      setDistricts(response.data.data);
    } catch (err) {
      if (locationApi.isCancel(err)) {
        return;
      }
    }
  };

  const loadDistrictsParts = async () => {
    try {
      const response = await locationApi.getDistrictParts();
      setDistrictsParts(response.data.data);
    } catch (err) {
      if (locationApi.isCancel(err)) {
        return;
      }
    }
  };

  const loadAuctionTypes = async () => {
    try {
      const response = await auctionsApi.getWebAuctionTypeList();
      setAuctionTypesData(response.data.data);
    } catch (err) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
    }
  };

  const loadMainCategories = async () => {
    try {
      const result: WebCategories[] = [{ id: '0', title: 'Všechny', count: 0 }];
      const response = await auctionCategoriesApi.getWebMainCategories(props.status as AuctionStatus, props.auctioneer);
      response.data.data.map((i) => {
        result[0].count += i.count;
        result.push({ id: i.id, title: i.title, count: i.count });
        return i;
      });
      setMainCategories(result);
    } catch (err) {
      if (auctionCategoriesApi.isCancel(err)) {
        return;
      }
      console.error(err);
    }
  };

  const loadSubCategories = async (itemId: string) => {
    itemId = itemId.toString();
    if (itemId === '0') {
      setSubCategories([]);
      return;
    }
    try {
      const response = await auctionCategoriesApi.getWebSubCategories(
        itemId,
        props.status as AuctionStatus,
        props.auctioneer
      );
      setSubCategories(
        response.data.data.map((i) => ({
          id: i.id,
          title: i.title,
          count: i.count,
        }))
      );
    } catch (err) {
      if (auctionCategoriesApi.isCancel(err)) {
        return;
      }
      console.error(err);
    }
  };

  const handleRegionChange = (region: Region, isChecked: boolean) => {
    region.id = region.id.toString();

    if (isChecked) {
      const regionsValue = union([...formik.values.regions, region.id]);
      formik.setFieldValue('regions', regionsValue);
      handleSearch({ regions: regionsValue });
      return;
    }

    const regionsValue = formik.values.regions.filter((item) => item !== region.id);
    formik.setFieldValue('regions', regionsValue);

    const unselectDistricts = districts
      .filter((item) => (item.region?.id || 0).toString() === region.id)
      .map((item) => item.id.toString());

    let unselectedDistrictsParts: string[] = [];
    unselectDistricts.forEach((districtId) => {
      unselectedDistrictsParts = unselectedDistrictsParts.concat(
        districtsParts
          .filter((dp) => dp.district?.id?.toString() === districtId)
          .map((districtPart) => districtPart.id.toString())
      );
    });

    const districtsValue = formik.values.districts.filter((item) => !~unselectDistricts.indexOf(item.toString()));
    formik.setFieldValue('districts', districtsValue);

    const districtsPartsValue = formik.values.districtsParts.filter(
      (item) => !~unselectedDistrictsParts.indexOf(item.toString())
    );
    formik.setFieldValue('districtsParts', districtsPartsValue);

    handleSearch({ regions: regionsValue, districts: districtsValue, districtsParts: districtsPartsValue });
  };

  const handleDistrictChange = (district: District, isChecked: boolean) => {
    district.id = district.id.toString();

    if (isChecked) {
      const districtsValue = union([...formik.values.districts, district.id]);
      formik.setFieldValue('districts', districtsValue);
      handleSearch({ districts: districtsValue });
      return;
    }

    const districtsValue = formik.values.districts.filter((item) => item !== district.id);
    formik.setFieldValue('districts', districtsValue);

    const unselectDistrictsParts = districtsParts
      .filter((item) => (item.district?.id || 0).toString() === district.id)
      .map((item) => item.id.toString());

    const districtsPartsValue = formik.values.districtsParts.filter(
      (item) => !~unselectDistrictsParts.indexOf(item.toString())
    );
    formik.setFieldValue('districtsParts', districtsPartsValue);

    handleSearch({ districts: districtsValue, districtsParts: districtsPartsValue });
  };

  const handleDistrictPartChange = (districtPart: DistrictPart, isChecked: boolean) => {
    districtPart.id = districtPart.id.toString();

    if (isChecked) {
      const districtsPartsValue = union([...formik.values.districtsParts, districtPart.id]);
      formik.setFieldValue('districtsParts', districtsPartsValue);
      handleSearch({ districtsParts: districtsPartsValue });
      return;
    }

    const districtsPartsValue = formik.values.districtsParts.filter((item) => item !== districtPart.id);
    formik.setFieldValue('districtsParts', districtsPartsValue);

    handleSearch({ districtsParts: districtsPartsValue });
  };

  const handleSubCategoryChange = (category: WebCategories, isChecked: boolean) => {
    if (isChecked) {
      const categoriesValue = union([...formik.values.subCategories, category.id.toString()]);
      formik.setFieldValue('subCategories', categoriesValue);
      handleSearch({ subCategories: categoriesValue });
      return;
    }

    const categoriesValues = formik.values.subCategories.filter((item) => item !== category.id.toString());
    formik.setFieldValue('subCategories', categoriesValues);
    handleSearch({ subCategories: categoriesValues });
  };

  const handleSearch = (options?: any) => {
    options = options || {};
    const inputSearch = get(options, 'search', formik.values.search);
    const inputPriceFrom = get(options, 'priceFrom', formik.values.priceFrom);
    const inputPriceTo = get(options, 'priceTo', formik.values.priceTo);
    const mainCategory = options.mainCategory || filterMenuActive;
    const inputAuctionType = get(options, 'auctionType', formik.values.auctionType);
    props.onSearch(
      {
        page: undefined,
        title: inputSearch || undefined,
        priceFrom: inputPriceFrom || undefined,
        priceTo: inputPriceTo || undefined,
        regions: options.regions || formik.values.regions,
        districts: options.districts || formik.values.districts,
        districtsParts: options.districtsParts || formik.values.districtsParts,
        mainCategory: mainCategory !== '0' ? mainCategory : undefined,
        subCategories: options.subCategories || formik.values.subCategories,
        auctionType: inputAuctionType || undefined,
        auctioneer: formik.values.auctioneer || undefined,
      },
      options.submit
    );
    if (props.closeWithSubmit && options.submit) {
      setOpenFilters(false);
    }
    if (options.submit) {
      window.scrollTo(0, 0);
    }
  };

  const handleMainMenuChange = (index: number) => {
    formik.setFieldValue('subCategories', []);
    const mainCategory: WebCategories | undefined = (mainCategories || [])[index];
    if (!!mainCategory) {
      handleSearch({ mainCategory: mainCategory?.id || '', subCategories: [] });
    }
  };

  const handleOpenToggle = () => {
    setOpenFilters(!openFilters);
    window.scrollTo(0, 0);
  };

  const getActiveSubCategories = (): WebCategories[] => {
    const result: WebCategories[] = [];
    formik.values.subCategories.map((i: string) => {
      const subCategory = subCategories.find((v) => v.id.toString() === i);
      if (!!subCategory) {
        result.push(subCategory);
      }
      return i;
    });
    return result;
  };

  const getActiveRegions = (): Region[] => {
    const result: Region[] = [];
    formik.values.regions.map((i: string) => {
      const region = regions.find((v) => v.id.toString() === i);
      if (!!region) {
        result.push(region);
      }
      return i;
    });
    return result;
  };

  const getActiveDistricts = (): District[] => {
    const result: District[] = [];
    formik.values.districts.map((i: string) => {
      const district = districts.find((v) => v.id.toString() === i);
      if (!!district) {
        result.push(district);
      }
      return i;
    });
    return result;
  };

  const getActiveDistrictsParts = (): DistrictPart[] => {
    const result: DistrictPart[] = [];
    formik.values.districtsParts.map((i: string) => {
      const districtPart = districtsParts.find((v) => v.id.toString() === i);
      if (!!districtPart) {
        result.push(districtPart);
      }
      return i;
    });
    return result;
  };

  const getAuctionTypeOptions = () => {
    return auctionTypesData.map((auctionType) => ({
      label: auctionType.translated,
      value: `${auctionType.type}`,
    }));
  };

  const handleActiveFilterInputRemove = (prop: string) => {
    formik.setFieldValue(prop, '');
    handleSearch({ [prop]: '' });
  };

  const handleAuctionTypeChange = (value: OnChangeValue<TypeSelectOptionType, boolean>) => {
    const itemValue = value as TypeSelectOptionType;
    formik.setFieldValue('auctionType', itemValue?.value || '');
    handleSearch({ auctionType: itemValue ? itemValue?.value : '' });
  };

  return (
    <div className="component-front-main-filter">
      <div
        className={classNames({
          'filters-hide': !openFilters,
          'mobile-open': mobileOpen,
          'mobile-toggle': props.mobileToggle,
        })}
      >
        <FrontTabsMenu
          onClick={handleMainMenuChange}
          items={(mainCategories || []).map((i) => ({
            label: i.title,
            count: i.count,
            isActive: filterMenuActive === i.id.toString(),
          }))}
        />
        <div className="filters-content">
          <form onSubmit={formik.handleSubmit}>
            <button type="submit" style={{ display: 'none' }} />
            <div className="content-item">
              {props.showPreloader && (
                <div className="preloader-content">
                  <BasePreloader className="filters-preloader" size={50} strokeWidth={5} />
                </div>
              )}
              {subCategories.length > 0 && (
                <div>
                  {subCategories.map((item: WebCategories, index: number) => (
                    <div className="filter-column" key={`filter-item-${index}`}>
                      <Form.Check
                        custom
                        type="checkbox"
                        label={
                          <>
                            {item.title}
                            {item.count !== undefined && <span>({item.count})</span>}
                          </>
                        }
                        id={`subcategory-${item.id.toString()}`}
                        name={`subcategory-${item.id.toString()}`}
                        checked={!!~formik.values.subCategories.indexOf(item.id.toString())}
                        onChange={(e) => {
                          handleSubCategoryChange(item, e.currentTarget.checked);
                        }}
                      />
                    </div>
                  ))}
                  <div className="block-separator mb-25" />
                </div>
              )}
              <div>
                {regions.map((item: Region, index: number) => (
                  <div className="filter-column" key={`filter-item-${index}`}>
                    <Form.Check
                      custom
                      type="checkbox"
                      label={item.name}
                      id={`region-${item.id}`}
                      name={`region-${item.id}`}
                      checked={!!~formik.values.regions.indexOf(item.id.toString())}
                      onChange={(e) => {
                        handleRegionChange(item, e.currentTarget.checked);
                      }}
                    />
                  </div>
                ))}
                {displayDistricts.length > 0 && <div className="block-separator mb-25" />}
              </div>
              <div>
                {displayDistricts.map((item: Region, index: number) => (
                  <div className="filter-column" key={`filter-item-${index}`}>
                    <Form.Check
                      custom
                      type="checkbox"
                      label={item.name}
                      id={`district-${item.id}`}
                      name={`district-${item.id}`}
                      checked={!!~formik.values.districts.indexOf(item.id.toString())}
                      onChange={(e) => {
                        handleDistrictChange(item, e.currentTarget.checked);
                      }}
                    />
                  </div>
                ))}
                {displayDistrictsParts.length > 0 && <div className="block-separator mb-25" />}
              </div>
              <div>
                {displayDistrictsParts.map((item: Region, index: number) => (
                  <div className="filter-column" key={`filter-item-${index}`}>
                    <Form.Check
                      custom
                      type="checkbox"
                      label={item.name}
                      id={`district-part-${item.id}`}
                      name={`district-part-${item.id}`}
                      checked={!!~formik.values.districtsParts.indexOf(item.id.toString())}
                      onChange={(e) => {
                        handleDistrictPartChange(item, e.currentTarget.checked);
                      }}
                    />
                  </div>
                ))}
              </div>
              {regions.length > 0 && <div className="block-separator" />}
              <div className={classNames(['extended-filters-content', { 'is-open': showExtendedFilters }])}>
                <Row className="extended-filters">
                  <Col xl={4}>
                    <h5 className="filter-title">Cena</h5>
                    <div className="filter-inputs price-inputs">
                      <div className="price-input">
                        <Form.Label>od</Form.Label>
                        <FormGroup
                          thousandSeparator
                          controlOnly
                          type="number"
                          name="priceFrom"
                          controlClassName="input-md left-input"
                          value={formik.values.priceFrom}
                          onBlur={() => handleSearch()}
                          onValueChange={(val) => formik.setFieldValue('priceFrom', val.value)}
                        />
                      </div>
                      <div className="price-input">
                        <Form.Label>do</Form.Label>
                        <FormGroup
                          thousandSeparator
                          controlOnly
                          type="number"
                          name="priceTo"
                          controlClassName="input-md"
                          value={formik.values.priceTo}
                          onBlur={() => handleSearch()}
                          onValueChange={(val) => formik.setFieldValue('priceTo', val.value)}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xl={4} lg={6} sm={12}>
                    <h5 className="filter-title">Číslo nebo název</h5>
                    <div className="filter-inputs">
                      <FormGroup
                        controlOnly
                        controlClassName="input-md input-search"
                        name="search"
                        value={formik.values.search}
                        onBlur={() => handleSearch()}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </Col>
                  {!props.hideType && (
                    <Col xl={4} lg={6} sm={12}>
                      <h5 className="filter-title">Typ</h5>
                      <div className="filter-inputs">
                        <Form.Group className="mb-2 input-md input-search select-input">
                          <Select
                            isClearable={true}
                            name="auctionType"
                            options={getAuctionTypeOptions()}
                            onChange={handleAuctionTypeChange}
                            value={getAuctionTypeOptions().find((auctionType) => {
                              return auctionType.value === formik.values.auctionType;
                            })}
                          />
                        </Form.Group>
                      </div>
                    </Col>
                  )}
                </Row>
                <div className="block-separator" />
              </div>
              <Row className="mt-4 mb-3 auctions-filter-actions">
                <Col md={12} lg={3} className="d-flex align-items-center flex-column flex-lg-row show-hide-filter">
                  <a href="/" onClick={handleExtendedFiltersClick} className="f-size-14 text-underline mr-0 mt-0">
                    {showExtendedFilters ? 'Zavřít rozšířené vyhledávání' : 'Rozšířené vyhledávání'}
                  </a>
                </Col>
                <Col
                  md={12}
                  lg={9}
                  className="mt-4 mt-lg-0 d-flex flex-column flex-lg-row align-items-center justify-content-lg-end auctions-info"
                >
                  <p className="search-result-count mb-0 mt-0 mr-0 mr-lg-35">
                    {props.auctionsLoaded ? (
                      <>
                        {getFoundTranslation(props.itemsCount)}{' '}
                        <span className="f-weight-700">
                          {`${getBaseNumberFormat(props.itemsCount)} ${getAuctionsTranslation(
                            props.status,
                            props.itemsCount
                          )}`}
                        </span>
                      </>
                    ) : (
                      <>&nbsp;</>
                    )}
                  </p>
                  <Button
                    type="button"
                    variant="btn-outline-primary"
                    className="mt-4 mt-lg-0 mb-2 mb-lg-0 auctions-button"
                    onClick={() => handleSearch({ submit: true })}
                  >
                    <img src={icoZoomColor15} alt="ico" className="mr-10 hover-hide" />
                    <img src={icoZoomWhite15} alt="ico" className="mr-10 hover-show" />
                    Zobrazit
                  </Button>
                </Col>
              </Row>
            </div>
            {props.withMap && (
              <div className="d-none d-lg-block filter-map">
                <MapComponent height={550} zoom={7} auctions={props.auctions} />
              </div>
            )}
          </form>
        </div>
      </div>

      {props.mobileToggle && (
        <div className={classNames(['active-filters-content mobile-content', { 'filters-open': mobileOpen }])}>
          <div className="w-100p toggle-content">
            <Button
              type="button"
              variant="btn-outline-primary"
              className="filters-open-toggle"
              onClick={() => setMobileOpen(!mobileOpen)}
            >
              <img src={showFiltersIcon} alt="ico" className="mr-10 hover-hide" />
              <img src={showFiltersIconActive} alt="ico" className="mr-10 hover-show" />
              {mobileOpen
                ? `Skrýt filtry${props.withMap ? ' a mapu' : ''}`
                : `Zobrazit filtry${props.withMap ? ' a mapu' : ''}`}
            </Button>
          </div>
        </div>
      )}

      {props.hasToggler && (
        <div className={classNames(['active-filters-content', { 'filters-open': openFilters }])}>
          <div className="w-100p toggle-content">
            <Button
              type="button"
              variant="btn-outline-primary"
              className="filters-open-toggle"
              onClick={handleOpenToggle}
            >
              <img src={showFiltersIcon} alt="ico" className="mr-10 hover-hide" />
              <img src={showFiltersIconActive} alt="ico" className="mr-10 hover-show" />
              {openFilters
                ? `Skrýt filtry${props.withMap ? ' a mapu' : ''}`
                : `Zobrazit filtry${props.withMap ? ' a mapu' : ''}`}
            </Button>
          </div>
          <div className="active-filters-items">
            {!!formik.values.priceFrom && (
              <div className="active-filter-item" onClick={() => handleActiveFilterInputRemove('priceFrom')}>
                Cena od: {getCurrencyFormat(formik.values.priceFrom)}
              </div>
            )}
            {!!formik.values.priceTo && (
              <div className="active-filter-item" onClick={() => handleActiveFilterInputRemove('priceTo')}>
                Cena do: {getCurrencyFormat(formik.values.priceTo)}
              </div>
            )}
            {!!formik.values.search && (
              <div className="active-filter-item" onClick={() => handleActiveFilterInputRemove('search')}>
                Číslo nebo název: {formik.values.search}
              </div>
            )}
            {mainCategories
              .filter((i) => i.id.toString() === filterMenuActive && i.id.toString() !== '0')
              .map((item, index) => (
                <div className="active-filter-item" key={`item-${index}`} onClick={() => handleMainMenuChange(0)}>
                  {item.title}
                </div>
              ))}
            {getActiveSubCategories().map((item, index) => (
              <div
                key={`item-${index}`}
                className="active-filter-item"
                onClick={() => handleSubCategoryChange(item, false)}
              >
                {item.title}
              </div>
            ))}
            {getActiveRegions().map((item, index) => (
              <div key={`item-${index}`} className="active-filter-item" onClick={() => handleRegionChange(item, false)}>
                {item.name}
              </div>
            ))}
            {getActiveDistricts().map((item, index) => (
              <div
                key={`item-${index}`}
                className="active-filter-item"
                onClick={() => handleDistrictChange(item, false)}
              >
                {item.name}
              </div>
            ))}
            {getActiveDistrictsParts().map((item, index) => (
              <div
                key={`item-${index}`}
                className="active-filter-item"
                onClick={() => handleDistrictPartChange(item, false)}
              >
                {item.name}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default FrontMainFilter;
