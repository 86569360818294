import React, { useEffect, useState, MouseEvent } from 'react';
import { useLocation } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import fileDownload from 'js-file-download';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';

import { getDateTimeFormat } from '@helpers/datetime';
import { useAuctionProtocolApi } from '@api/auctionProtocol';
import { BasePreloader, Button, Pagination } from '@components';
import { AuctionProtocolListResponse, AuctionResponse } from '@types';

type Props = {
  auction: AuctionResponse;
};

const ProtocolSection: React.FC<Props> = (props) => {
  const perPage = 15;
  const location = useLocation();
  const auctionProtocolApi = useAuctionProtocolApi();
  const [isSaved, setIsSaved] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState<AuctionProtocolListResponse[]>([]);
  const [dataLen, setDataLen] = useState(0);
  const defaultQuery = React.useMemo(
    () => ({
      page: withDefault(NumberParam, 1),
    }),
    []
  );
  const [query, setQuery] = useQueryParams(defaultQuery);

  useEffect(() => {
    loadData();
    return () => auctionProtocolApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, query]);

  const loadData = async () => {
    try {
      const res = await auctionProtocolApi.protocolList(props.auction.id.toString(), { ...query, perPage });
      setData(res.data.data);
      setDataLen(res.data.total || 0);
      setIsLoaded(true);
    } catch (err: any) {
      if (auctionProtocolApi.isCancel(err)) {
        return;
      }
    }
  };

  const protocolGenerate = async () => {
    setIsSaved(false);
    try {
      await auctionProtocolApi.protocolGenerate(props.auction.id.toString());
      await loadData();
    } catch (err: any) {
      if (auctionProtocolApi.isCancel(err)) {
        return;
      }
    }
    setIsSaved(true);
  };

  const getProtocolName = () => {
    const auctionNumber = props.auction.number;

    if (props.auction.auctionType === "auction") {
      return `a_protocol_${auctionNumber}.pdf`;
    }

    if (props.auction.auctionType === "public_competition") {
      return `vs_protocol_${auctionNumber}.pdf`;
    }

    return `d_protocol_${auctionNumber}.pdf`;
  }

  const protocolDownload = async (e: MouseEvent<HTMLAnchorElement>, protocolId: string) => {
    e.preventDefault();
    try {
      const res = await auctionProtocolApi.protocolDownload(props.auction.id.toString(), protocolId);

      fileDownload(res.data, getProtocolName());
    } catch (err: any) {
      if (auctionProtocolApi.isCancel(err)) {
        return;
      }
    }
  };

  const renderContent = () => {
    if (!isLoaded) {
      return (
        <div className="pt-5 pb-4">
          <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
            <BasePreloader />
          </div>
        </div>
      );
    }

    return (
      <div>
        <Button type="button" disabled={!isSaved} onClick={() => protocolGenerate()}>
          Vytvořit neanonymizovaný záznam
        </Button>

        <div className="pt-4">
          <div className="responsive-table-content">
            <Table className="border-bottom-0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Vytvořen (kdy)</th>
                  <th>Typ záznamu</th>
                  <th>Pro stav</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={`list-item-${index}`} className="border-top">
                    <td>{item.id}</td>
                    <td>{getDateTimeFormat(item.timeCreated)}</td>
                    <td>{item.typeTranslation}</td>
                    <td>{item.auctionStatusTranslation}</td>
                    <td className="text-right">
                      <a href="/" onClick={(e) => protocolDownload(e, item.id)}>
                        stáhnout
                      </a>
                    </td>
                  </tr>
                ))}
                {data.length < 1 && (
                  <tr>
                    <td className="text-left" colSpan={100}>
                      Nebyla nalezena žádná data.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
        {dataLen > perPage && (
          <div className="mt-2">
            <Pagination
              page={query.page}
              perPage={perPage}
              totalRows={dataLen}
              onPageChange={(page) => setQuery({ ...query, page: page }, 'push')}
            />
          </div>
        )}
      </div>
    );
  };

  return <div className="component-admin-box-content">{renderContent()}</div>;
};

export default ProtocolSection;
