import { BaseFrontPage } from '@components';
import {
  FrontSidebarBanners,
  FrontSidebarMenu,
  FrontSidebarNotifications,
  FrontSidebarStatistics,
} from '@components/FrontSidebarBox';

import { DefaultPage } from '@components/DefaultPage/DefaultPage';
import { useEffect } from 'react';

type DefaultPageProps = {
  route: string;
};

export const DefaultPageComponent = (props: DefaultPageProps) => {
  useEffect(() => {
    window?.scrollTo(0, 0);
  }, []);

  return (
    <BaseFrontPage
      disableScrollToTop
      sidebarItems={[
        <FrontSidebarMenu title="Dražby a veřejné soutěže" />,
        <FrontSidebarNotifications title="Upozornění na Váš e-mail" />,
        <FrontSidebarStatistics title="Exdražby v číslech" />,
        <FrontSidebarBanners />,
      ]}
    >
      <DefaultPage route={props.route} />
    </BaseFrontPage>
  );
};
