import { Col, Form, Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { isArray } from 'lodash';
import { OnChangeValue } from 'react-select';
import { FormikValues, useFormik } from 'formik';

import { useAuctionsApi } from '@api/auctions';
import { FormGroup, Select } from '@components';
import { WebAuctionsFilter, AuctionType, EnumType } from '@types';

import icoColorSearch from '@assets/images/front/auctioneers/ico-color-search.svg';

type FrontAuctioneerAuctionsFilterProps = {
  values: any;
  onSubmit: (values: WebAuctionsFilter) => void;
};

interface TypeSelectOptionType {
  value: string;
  label: string;
}

export const FrontAuctioneerAuctionsFilter = (props: FrontAuctioneerAuctionsFilterProps) => {
  const auctionsApi = useAuctionsApi();
  const [auctionTypesData, setAuctionTypesData] = useState([] as Array<AuctionType>);
  const [auctionStatus, setAuctionStatus] = useState<EnumType[]>([]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: props.values,
    onSubmit: (values) => handleSubmit(values),
  });

  useEffect(() => {
    loadAuctionTypes();
    loadAuctionStatus();
    formik.resetForm();
    return () => auctionsApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values]);

  const loadAuctionTypes = async () => {
    try {
      const response = await auctionsApi.getWebAuctionTypeList();
      setAuctionTypesData(response.data.data);
    } catch (err) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
    }
  };

  const loadAuctionStatus = async () => {
    try {
      const response = await auctionsApi.getAllStatuses();
      setAuctionStatus(response.data.data);
    } catch (err) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
    }
  };

  const handleSubmit = (values: FormikValues) => {
    const formValues = { ...values };
    Object.getOwnPropertyNames(formValues).map((prop) => {
      if (formValues[prop] === '') {
        formValues[prop] = undefined;
      }
      return prop;
    });
    formValues.page = 1;
    formValues.auctionType = formValues.auctionType || undefined;
    props.onSubmit(formValues as WebAuctionsFilter);
  };

  const getAuctionTypeOptions = () => {
    return auctionTypesData.map((auctionType) => ({
      label: auctionType.translated,
      value: `${auctionType.type}`,
    }));
  };

  const getStatusOptions = () => {
    return auctionStatus.map((status) => ({
      label: status.translated,
      value: `${status.type}`,
    }));
  };

  const handleAuctionTypeChange = (value: OnChangeValue<TypeSelectOptionType, boolean>) => {
    const itemValue = value as TypeSelectOptionType;
    formik.setFieldValue('auctionType', itemValue?.value || '');
  };

  const handleStatusChange = (value: OnChangeValue<TypeSelectOptionType, boolean>) => {
    const itemValue = value as TypeSelectOptionType;
    formik.setFieldValue('status', itemValue?.value || '');
  };

  return (
    <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)}>
      <div className="front-auctioneer-filter">
        <Row className="extended-filters">
          <Col md={6} className="mb-4">
            <div className="filter-input">
              <Form.Label>Typ</Form.Label>
              <Form.Group className="input-search select-input input-md">
                <Select
                  isClearable={true}
                  options={getAuctionTypeOptions()}
                  onChange={handleAuctionTypeChange}
                  value={getAuctionTypeOptions().find((auctionType) => {
                    return auctionType.value === formik.values.auctionType;
                  })}
                />
              </Form.Group>
            </div>
          </Col>
          <Col md={6} className="mb-4">
            <div className="filter-input">
              <Form.Label>Průběh dražby / veřejné soutěže</Form.Label>
              <Form.Group className="input-search select-input input-md">
                <Select
                  isClearable={true}
                  options={getStatusOptions()}
                  onChange={handleStatusChange}
                  value={getStatusOptions().find((status) => {
                    return isArray(formik.values.status)
                      ? formik.values.status.some((item: string) => item === status.value)
                      : status.value === formik.values.status;
                  })}
                />
              </Form.Group>
            </div>
          </Col>
          <Col md={12}>
            <div className="filter-input">
              <Form.Label>Číslo nebo název</Form.Label>
              <FormGroup
                controlOnly
                controlClassName="input-md input-search"
                name="search"
                onChange={formik.handleChange}
                value={formik.values.search}
              />
              <button type="submit" className="ml-10 search-button">
                <img src={icoColorSearch} alt="ico" />
              </button>
            </div>
          </Col>
        </Row>
      </div>
    </Form>
  );
};
