import React, { useState } from 'react';

import { Button, Modal } from '@components';
import { AuctionInformedConsent } from '@types';
import { useInformedConsentApi } from '@api/informedConsent';
import BasePreloader from '@components/BasePreloader/BasePreloader';

type Props = {
  auctionId: string | number;
  informedConsents: AuctionInformedConsent[];
  onSuccess: () => void;
};

export const InformedConsentsModal: React.FC<Props> = (props) => {
  const informedConsentApi = useInformedConsentApi();
  const [consents, setConsents] = useState(props.informedConsents);
  const [showLoading, setShowLoading] = useState(false);

  const handleApprovedChange = (item: AuctionInformedConsent, approved: boolean) => {
    const currentConsents = [...consents];
    for (const index in currentConsents) {
      if (currentConsents[index].id === item.id) {
        currentConsents[index].approved = approved;
      }
    }
    setConsents(currentConsents);
  };

  const allApproved = () => consents.filter((ic) => !ic.approved).length === 0;

  const handleApprovedClick = async () => {
    if (!allApproved()) {
      return;
    }
    setShowLoading(true);
    try {
      await informedConsentApi.informedConsentApproved(props.auctionId);
      props.onSuccess();
    } catch (err: any) {
      if (informedConsentApi.isCancel(err)) {
        return;
      }
      setShowLoading(false);
    }
  };

  return (
    <Modal isOpen hideClose title="Schválení podmínek" className="component-informed-consents-modal">
      {consents.map((item) => (
        <div className="items-row" key={`informed-consent-${item.id}`}>
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id={`informed-consent-${item.id}`}
              name={`informed-consent-${item.id}`}
              checked={item.approved}
              onChange={(e) => handleApprovedChange(item, e.target.checked)}
            />
            <label
              className="custom-control-label"
              htmlFor={`informed-consent-${item.id}`}
              dangerouslySetInnerHTML={{ __html: item.text }}
            />
          </div>
        </div>
      ))}

      <div className="items-row pt-2 d-flex justify-content-end">
        {showLoading ? (
          <BasePreloader />
        ) : (
          <Button type="button" disabled={!allApproved()} onClick={() => handleApprovedClick()}>
            Četl/a jsem a souhlasím
          </Button>
        )}
      </div>
    </Modal>
  );
};
