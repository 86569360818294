import React from 'react';
import { Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

import * as routes from '@routes';
import { useAuctionsApi } from '@api/auctions';
import { BasePreloader, Button } from '@components';
import { closeMessage, confirmMessage } from '@helpers/messages';
import { useAuthStore, useCurrentUserRoleChecks, usePageStore, userRoleChecks, useUrlSearchStore } from '@stores';

import colorIcoDel19 from '@assets/images/color-ico-del-19.svg';
import colorIcoLeft20 from '@assets/images/color-ico-left-20.svg';
import { AuctionStatus, User } from '@types';

interface Props {
  id: string;
  state: string;
  status: string;
  back?: string;
  isSubmitting: boolean;
  auctionType: 'auction' | 'auction_public' | 'public_competition';
  getTranslation: (key: string) => string;
  onCreateClick: () => void;
  onChangeState: (state: 'active' | 'deactive') => void;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
}

const AuctionActions: React.FC<Props> = (props) => {
  const pageState = usePageStore();
  const urlSearchState = useUrlSearchStore();
  const auctionsApi = useAuctionsApi();
  const navigate = useNavigate();
  const currentUserRole = useCurrentUserRoleChecks();
  const authState = useAuthStore();
  const { isOfRoleSuperAdmin } = userRoleChecks(authState.user as User);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => () => auctionsApi.cancelAllRequests(), []);

  const handleDeleteClick = (e: React.MouseEvent) => {
    e.preventDefault();
    confirmMessage({
      isHtml: true,
      title: props.getTranslation('auction_actions_delete_confirm_title'),
      text: props.getTranslation('auction_actions_delete_confirm_text'),
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return auctionsApi.auctionDelete(props.id).catch(() => closeMessage());
      },
    }).then((res) => {
      if (!!res.value && (res.value.status === 200 || res.value.status === 204)) {
        navigate(`${pageState.getPagePath(routes.admin.AUCTIONS_LIST)}${urlSearchState.adminAuctionsList}`);
      }
    });
  };

  const stateToggle = () => {
    let text = '';
    let buttonText = '';
    if (props.state === 'deactive') {
      text = props.getTranslation('auction_actions_confirm_activation_text');
      buttonText = props.getTranslation('auction_actions_confirm_activation_btn');
    } else if (props.state === 'active') {
      text = props.getTranslation('auction_actions_confirm_deactivation_text');
      buttonText = props.getTranslation('auction_actions_confirm_deactivation_btn');
    } else {
      return;
    }
    confirmMessage({
      isHtml: true,
      title: props.getTranslation('auction_actions_confirm_title'),
      text: `${text}<br><br>${props.getTranslation('auction_actions_confirm_help_text')}`,
      confirmButtonText: buttonText,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        if (props.state === 'deactive') {
          return auctionsApi.auctionActivate(props.id).catch(() => closeMessage());
        } else {
          return auctionsApi.auctionDeactive(props.id).catch(() => closeMessage());
        }
      },
    }).then((res) => {
      if (!!res.value && (res.value.status === 200 || res.value.status === 204)) {
        props.onChangeState(props.state === 'deactive' ? 'active' : 'deactive');
      }
    });
  };

  const getBackLabel = () => {
    switch (props.back) {
      case 'admin-buyers':
        return 'Zpět na doklady a jistoty';
      case 'admin-security':
        return 'Zpět na doklady a jistoty';
      default:
        return props.getTranslation('auction_actions_btn_back');
    }
  };

  const getBackUrl = () => {
    switch (props.back) {
      case 'admin-buyers':
        return pageState.getPagePath(routes.admin.DOCUMENTS_AND_SECURITY);
      case 'admin-security':
        return `${pageState.getPagePath(routes.admin.DOCUMENTS_AND_SECURITY)}?s=2`;
      default:
        return `${pageState.getPagePath(routes.admin.AUCTIONS_LIST)}${urlSearchState.adminAuctionsList}`;
    }
  };

  return (
    <div className="auction-actions">
      <div className="panel-content">
        <div className="panel-left">
          <Button disabled={props.isSubmitting} type="button" onClick={() => props.handleSubmit()}>
            {props.getTranslation('auction_actions_btn_save')}
          </Button>
          <Button
            type="button"
            onClick={() =>
              navigate(
                `${pageState.getPagePath(routes.admin.AUCTIONS_CREATE)}?copyFrom=${props.id}&type=${
                  props.auctionType === 'auction' ? 'auction' : props.auctionType === 'public_competition' ? 'public_competition' : 'public'
                }`,
              )
            }
          >
            {props.getTranslation('auction_actions_btn_create_copy')}
          </Button>
          <Button onClick={props.onCreateClick}>{props.getTranslation('auction_actions_btn_create_auction')}</Button>
          {!isOfRoleSuperAdmin() && props.status !== AuctionStatus.prepared ? (
            <></>
          ) : (
            <div className="auction-state">
              <div className="action-label margin-left">{props.getTranslation('auction_actions_label_online')}</div>
              <Form.Check
                type="switch"
                id="auction-state"
                className="auction-switch"
                checked={props.state === 'active'}
                onChange={() => stateToggle()}
              />
            </div>
          )}
          {props.isSubmitting && <BasePreloader className="actions-preloader" size={25} />}
        </div>
        <div className="panel-right">
          {!isOfRoleSuperAdmin() && props.status !== AuctionStatus.prepared ? (
            <></>
          ) : (
            <>
              {!currentUserRole.isOfRoleAuctioneerEditor() && (
                <a href="/" onClick={handleDeleteClick}>
                  <img src={colorIcoDel19} alt="icon" className="mr-2" />
                  {props.getTranslation('auction_actions_btn_delete')}
                </a>
              )}
            </>
          )}

          <Link to={getBackUrl()}>
            <img src={colorIcoLeft20} alt="icon" className="mr-2" />
            {getBackLabel()}
          </Link>
        </div>
        <div className="clearfix" />
      </div>
    </div>
  );
};

export default AuctionActions;
