import React from 'react';
import _ from 'lodash';
import { OnChangeValue } from 'react-select';
import { Col, Form, Row } from 'react-bootstrap';
import { FormikErrors, FormikValues } from 'formik';

import { useAuctionsApi } from '@api/auctions';
import { useLocationApi } from '@api/location';
import { getZipFormat } from '@helpers/formats';
import { arrKeyExists } from '@helpers/validator';
import { useAuctioneersApi } from '@api/auctioneers';
import { useAuctionCategoriesApi } from '@api/auctionCategories';
import { AddressType, AuctioneerAddress, AuctionFieldsEnabled } from '@types';
import { BasePreloader, Button, ControlFeedback, FormGroup, Select, MapAddressModal } from '@components';

interface Props {
  id?: string;
  values: FormikValues;
  isSubmitting: boolean;
  currentCategoryType: string;
  fieldsEnabled?: AuctionFieldsEnabled;
  errors: FormikErrors<FormikValues>;
  requiredParametersFields: string[];
  auctionType: 'auction' | 'auction_public' | 'public_competition';
  getTranslation: (key: string) => string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(
      field: T_1
    ): T_1 extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
  };
}

interface SelectOptionType {
  value: string;
  label: string;
}

const LocationSection: React.FC<Props> = (props) => {
  const auctionsApi = useAuctionsApi();
  const auctioneersApi = useAuctioneersApi();
  const auctionCategoriesApi = useAuctionCategoriesApi();
  const locationApi = useLocationApi();

  const [showMapModal, setShowMapModal] = React.useState(false);
  const [showAuctioneerAddress, setShowAuctioneerAddress] = React.useState(!props.id);
  const [addressLoaded, setAddressLoaded] = React.useState(false);
  const [auctioneerAddressData, setAuctioneerAddressData] = React.useState<AuctioneerAddress[]>([]);
  const [countryOptions, setCountryOptions] = React.useState<Array<SelectOptionType>>([]);
  const [regionOptions, setRegionOptions] = React.useState<Array<SelectOptionType>>([]);
  const [districtOptions, setDistrictOptions] = React.useState<Array<SelectOptionType>>([]);
  const [isDistrictOptionsLoading, setIsDistrictOptionsLoading] = React.useState(false);
  const [districtPartsOptions, setDistrictPartsOptions] = React.useState<Array<SelectOptionType>>([]);
  const [isDistrictPartsOptionsLoading, setIsDistrictPartsOptionsLoading] = React.useState(false);

  React.useEffect(() => {
    loadAuctioneerAddress();
    if (!!props.id) {
      props.setFieldValue('auctioneerAddress', 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAuctioneerAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toString();
    props.setFieldValue('auctioneerAddress', value);
    const currentAddress = auctioneerAddressData.find((address) => address.id.toString() === value);

    if (!!currentAddress) {
      props.setFieldValue('addressCountry', currentAddress.country?.id || '');
      props.setFieldValue('addressCadastralArea', currentAddress.cadastralArea || '');
      props.setFieldValue('addressRegion', currentAddress.region?.id || '');
      props.setFieldValue('addressDistrict', currentAddress.district?.id || '');
      props.setFieldValue('addressDistrictPart', currentAddress.districtPart?.id || '');
      props.setFieldValue('addressCity', currentAddress.city || '');
      props.setFieldValue('addressZipCode', currentAddress.zipCode || '');
      props.setFieldValue('addressStreet', currentAddress.street || '');
      props.setFieldValue('addressHouseNumber', currentAddress.houseNumber || '');
      props.setFieldValue('addressRuian', currentAddress.ruian || '');
      props.setFieldValue('addressGps', currentAddress.gps);

      if (!!props.id) {
        setShowAuctioneerAddress(false);
      }
    } else {
      props.setFieldValue('addressCountry', '');
      props.setFieldValue('addressCadastralArea', '');
      props.setFieldValue('addressRegion', '');
      props.setFieldValue('addressDistrict', '');
      props.setFieldValue('addressDistrictPart', '');
      props.setFieldValue('addressCity', '');
      props.setFieldValue('addressStreet', '');
      props.setFieldValue('addressHouseNumber', '');
      props.setFieldValue('addressRuian', '');
      props.setFieldValue('addressGps', '');
    }
  };

  const loadAuctioneerAddress = async () => {
    try {
      const auctioneer = (await auctioneersApi.detail(props.values.auctioneer)).data.data;
      const auctioneerAddressList = (await auctioneersApi.establishmentsList(props.values.auctioneer as string)).data
        ?.data;
      if (auctioneer.deliveryAddress) {
        auctioneerAddressList?.unshift({
          ...auctioneer.deliveryAddress,
          id: 'delivery',
        });
      }
      if (auctioneer.invoiceAddress) {
        auctioneerAddressList?.unshift({
          ...auctioneer.invoiceAddress,
          id: 'invoice',
        });
      }
      setAddressLoaded(true);
      setAuctioneerAddressData(auctioneerAddressList);
    } catch (err) {
      if (auctioneersApi.isCancel(err)) {
        return;
      }
      setAddressLoaded(true);
    }
  };

  const loadCountryOptions = async () => {
    try {
      const response = await locationApi.getCountries();
      setCountryOptions(
        response.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
      if (!props.values.addressCountry && response.data.data.length > 0) {
        props.setFieldValue('addressCountry', response.data.data[0].id);
      }
    } catch (err) {
      if (locationApi.isCancel(err)) {
        return;
      }
    }
  };

  const loadRegionOptions = async (countryId: string) => {
    try {
      const response = await locationApi.getRegions(countryId);
      setRegionOptions(
        response.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
    } catch (err) {}
  };

  React.useEffect(() => {
    if (props.values.addressCountry) {
      loadRegionOptions(props.values.addressCountry);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values.addressCountry]);

  const loadDistrictOptions = async (regionId: string) => {
    setIsDistrictOptionsLoading(true);

    try {
      const respose = await locationApi.getDistricts(regionId);
      setDistrictOptions(
        respose.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
    } catch (err) {
    } finally {
      setIsDistrictOptionsLoading(false);
    }
  };

  React.useEffect(() => {
    if (props.values.addressRegion) {
      loadDistrictOptions(props.values.addressRegion);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values.addressRegion]);

  const loadDistrictPartsOptions = async (districtId: string) => {
    if (!districtId) {
      setDistrictPartsOptions([]);
    }

    setIsDistrictPartsOptionsLoading(true);

    try {
      const respose = await locationApi.getDistrictParts(districtId);
      setDistrictPartsOptions(
        respose.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
    } catch (err) {
    } finally {
      setIsDistrictPartsOptionsLoading(false);
    }
  };

  React.useEffect(() => {
    if (props.values.addressRegion) {
      loadDistrictPartsOptions(props.values.addressDistrict);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values.addressDistrict]);

  React.useEffect(() => {
    loadCountryOptions();

    if (props.values.addressCountry) {
      loadRegionOptions(props.values.addressCountry);
    }

    if (props.values.addressRegion) {
      loadDistrictOptions(props.values.addressRegion);
    }

    return () => {
      auctionsApi.cancelAllRequests();
      auctionCategoriesApi.cancelAllRequests();
      locationApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCountryChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('addressCountry', itemValue?.value || '');

    if (props.values.addressCountry !== itemValue?.value) {
      props.setFieldValue('addressRegion', '');
      props.setFieldValue('addressDistrict', '');
      props.setFieldValue('addressDistrictPart', '');
    }
  };

  const handleRegionChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('addressRegion', itemValue?.value || '');

    if (props.values.addressRegion !== itemValue?.value) {
      props.setFieldValue('addressDistrict', '');
      props.setFieldValue('addressDistrictPart', '');
    }
  };

  const handleDistrictChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('addressDistrict', itemValue?.value || '');

    if (props.values.addressDistrict !== itemValue?.value) {
      props.setFieldValue('addressDistrictPart', '');
    }
  };

  const handleDistrictPartChange = (value: OnChangeValue<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('addressDistrictPart', itemValue?.value || '');
  };

  const handleSelectFromMap = (v: AddressType) => {
    props.setFieldValue('addressGps', v.addressGps);
    props.setFieldValue('addressCity', v.addressCity);
    props.setFieldValue('addressZipCode', v.addressZipCode);
    props.setFieldValue('addressStreet', v.addressStreet);
    props.setFieldValue('addressHouseNumber', v.addressHouseNumber);
    props.setFieldValue('addressRuian', v.addressRuian);
    props.setFieldValue('addressCadastralArea', v.addressCadastral);
    props.setFieldValue('addressCountry', v.addressCountry ? parseInt(v.addressCountry) : undefined);
    props.setFieldValue('addressRegion', v.addressRegion ? parseInt(v.addressRegion) : undefined);
    props.setFieldValue('addressDistrict', v.addressDistrict ? parseInt(v.addressDistrict) : undefined);
    props.setFieldValue('addressDistrictPart', v.addressDistrictPart ? parseInt(v.addressDistrictPart) : undefined);
    setShowMapModal(false);
  };

  const renderAuctioneerAddress = () => {
    if (!showAuctioneerAddress && !!props.id) {
      return (
        <div className="mt-35">
          <Button type="button" onClick={() => setShowAuctioneerAddress(true)}>
            {props.getTranslation('tab_location_btn_another_address')}
          </Button>
        </div>
      );
    }
    return (
      <>
        <div className="mt-4">
          <Button type="button" onClick={() => setShowMapModal(true)}>
            Vybrat z mapy
          </Button>
        </div>

        <div className="mt-35">
          {addressLoaded ? (
            <>
              {auctioneerAddressData.map((item, index) => (
                <div key={`address-${index}`} className="mb-3">
                  <Form.Check
                    custom
                    type="radio"
                    id={`address-${index}`}
                    name="auctioneerAddress"
                    className="radio-point"
                    value={item.id}
                    label={`${item.street} ${item.houseNumber}, ${item.city}, ${getZipFormat(item.zipCode || '')}`}
                    checked={item.id.toString() === (props.values.auctioneerAddress || '').toString()}
                    onChange={handleAuctioneerAddressChange}
                  />
                </div>
              ))}
            </>
          ) : (
            <div className="mb-3">
              <BasePreloader size={20} />
            </div>
          )}
          {!props.id && (
            <Form.Check
              custom
              type="radio"
              id="address-other"
              name="auctioneerAddress"
              className="radio-point"
              label="Jiná"
              value={0}
              checked={props.values.auctioneerAddress === 0}
              onChange={handleAuctioneerAddressChange}
            />
          )}
        </div>
      </>
    );
  };

  return (
    <div>
      <div className="pl-2">
        <h2 className="f-weight-300 f-size-25">{props.getTranslation('tab_location_title')}</h2>

        {_.get(props.fieldsEnabled, 'auctionAddress', true) && <>{renderAuctioneerAddress()}</>}

        <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => props.handleSubmit(e)} className="mt-40">
          <div className="responsive-table-content">
            <div className="pt-2">
              <Form.Group className="f-inline-group">
                <Form.Label className="f-inline-label text-left">
                  {props.getTranslation('tab_location_label_auction_address_country')} *
                </Form.Label>
                <div className="f-inline-control">
                  <div className="w-max-500">
                    <Select
                      name="auctionAddress_country"
                      size="md"
                      isDisabled={!_.get(props.fieldsEnabled, 'auctionAddress', true)}
                      isInvalid={!!props.errors.addressCountry}
                      options={countryOptions}
                      value={
                        countryOptions.find(
                          (i) => i.value.toString() === (props.values.addressCountry || '').toString()
                        ) || null
                      }
                      onChange={handleCountryChange}
                    />
                    {!!props.errors.addressCountry && (
                      <ControlFeedback type="invalid">{props.errors.addressCountry as string}</ControlFeedback>
                    )}
                  </div>
                </div>
              </Form.Group>
              <Form.Group className="f-inline-group">
                <Form.Label className="f-inline-label text-left">
                  {props.getTranslation('tab_location_label_auction_address_region')} *
                </Form.Label>
                <div className="f-inline-control">
                  <div className="w-max-500">
                    <Select
                      size="md"
                      name="auctionAddress_region"
                      isDisabled={!_.get(props.fieldsEnabled, 'auctionAddress', true)}
                      isInvalid={!!props.errors.auctionAddress_region}
                      options={regionOptions}
                      value={
                        regionOptions.find(
                          (i) => i.value.toString() === (props.values.addressRegion || '').toString()
                        ) || null
                      }
                      onChange={handleRegionChange}
                    />
                    {!!props.errors.auctionAddress_region && (
                      <ControlFeedback type="invalid">{props.errors.auctionAddress_region as string}</ControlFeedback>
                    )}
                  </div>
                </div>
              </Form.Group>

              <Form.Group className="f-inline-group">
                <Form.Label className="f-inline-label text-left">
                  {props.getTranslation('tab_location_label_auction_address_district')} *
                </Form.Label>
                <div className="f-inline-control">
                  <div className="w-max-500">
                    <Select
                      size="md"
                      name="auctionAddress_district"
                      isInvalid={!!props.errors.auctionAddress_district}
                      options={districtOptions}
                      isDisabled={!_.get(props.fieldsEnabled, 'auctionAddress', true)}
                      value={
                        districtOptions.find(
                          (i) => i.value.toString() === (props.values.addressDistrict || '').toString()
                        ) || null
                      }
                      onChange={handleDistrictChange}
                      isLoading={isDistrictOptionsLoading}
                    />
                    {!!props.errors.auctionAddress_district && (
                      <ControlFeedback type="invalid">{props.errors.auctionAddress_district as string}</ControlFeedback>
                    )}
                  </div>
                </div>
              </Form.Group>

              {districtPartsOptions.length > 0 && (
                <Form.Group className="f-inline-group">
                  <Form.Label className="f-inline-label text-left">
                    {props.getTranslation('tab_location_label_auction_address_districtPart')}
                  </Form.Label>
                  <div className="f-inline-control">
                    <div className="w-max-500">
                      <Select
                        isClearable
                        size="md"
                        name="auctionAddress_district"
                        isInvalid={!!props.errors.auctionAddress_districtPart}
                        options={districtPartsOptions}
                        isDisabled={!_.get(props.fieldsEnabled, 'auctionAddress', true)}
                        value={
                          districtPartsOptions.find(
                            (i) => i.value.toString() === (props.values.addressDistrictPart || '').toString()
                          ) || null
                        }
                        onChange={handleDistrictPartChange}
                        isLoading={isDistrictPartsOptionsLoading}
                      />
                      {!!props.errors.auctionAddress_districtPart && (
                        <ControlFeedback type="invalid">
                          {props.errors.auctionAddress_districtPart as string}
                        </ControlFeedback>
                      )}
                    </div>
                  </div>
                </Form.Group>
              )}

              <FormGroup
                required
                name="addressCity"
                label={props.getTranslation('tab_location_label_address_city')}
                labelClassName="text-left"
                readOnly={!_.get(props.fieldsEnabled, 'auctionAddress', true)}
                error={props.errors.auctionAddress_city as string}
                value={props.values.addressCity}
                onChange={props.handleChange}
              />
              {props.currentCategoryType !== 'real_estate_land' ? (
                <>
                  <FormGroup
                    name="addressStreet"
                    label={props.getTranslation('tab_location_label_address_street')}
                    required={arrKeyExists(props.requiredParametersFields, 'street')}
                    labelClassName="text-left"
                    error={props.errors.auctionAddress_street as string}
                    value={props.values.addressStreet}
                    readOnly={!_.get(props.fieldsEnabled, 'auctionAddress', true)}
                    onChange={props.handleChange}
                  />
                  <FormGroup
                    name="addressHouseNumber"
                    required={props.values.mandatoryHouseNumber ?? true}
                    label={props.getTranslation('tab_location_label_address_house_number')}
                    labelClassName="text-left"
                    error={props.errors.auctionAddress_houseNumber as string}
                    value={props.values.addressHouseNumber}
                    readOnly={!_.get(props.fieldsEnabled, 'auctionAddress', true)}
                    onChange={props.handleChange}
                  />
                  <FormGroup
                    required
                    name="addressZipCode"
                    label={props.getTranslation('tab_location_label_address_zip_code')}
                    labelClassName="text-left"
                    readOnly={!_.get(props.fieldsEnabled, 'auctionAddress', true)}
                    error={props.errors.auctionAddress_zipCode as string}
                    value={props.values.addressZipCode}
                    onChange={props.handleChange}
                  />
                  <FormGroup
                    required
                    name="addressCadastralArea"
                    label={props.getTranslation('tab_location_label_address_cadastral_area')}
                    labelClassName="text-left"
                    error={props.errors.auctionAddress_cadastralArea as string}
                    value={props.values.addressCadastralArea}
                    readOnly={!_.get(props.fieldsEnabled, 'auctionAddress', true)}
                    onChange={props.handleChange}
                  />
                </>
              ) : (
                <>
                  <FormGroup
                    required
                    name="addressCadastralArea"
                    label={props.getTranslation('tab_location_label_address_cadastral_area')}
                    labelClassName="text-left"
                    error={props.errors.auctionAddress_cadastralArea as string}
                    value={props.values.addressCadastralArea}
                    readOnly={!_.get(props.fieldsEnabled, 'auctionAddress', true)}
                    onChange={props.handleChange}
                  />
                  <FormGroup
                    name="addressLandNumber"
                    label={props.getTranslation('tab_location_label_address_land_number')}
                    labelClassName="text-left"
                    error={props.errors.auctionAddress_landNumber as string}
                    value={props.values.addressLandNumber}
                    readOnly={!_.get(props.fieldsEnabled, 'auctionAddress', true)}
                    onChange={props.handleChange}
                  />
                  <FormGroup
                    required
                    name="addressZipCode"
                    label={props.getTranslation('tab_location_label_address_zip_code')}
                    labelClassName="text-left"
                    readOnly={!_.get(props.fieldsEnabled, 'auctionAddress', true)}
                    error={props.errors.auctionAddress_zipCode as string}
                    value={props.values.addressZipCode}
                    onChange={props.handleChange}
                  />
                </>
              )}
              <FormGroup
                name="addressRuian"
                label={props.getTranslation('tab_location_label_address_ruian')}
                labelClassName="text-left"
                type="number"
                error={props.errors.auctionAddress_ruian as string}
                value={props.values.addressRuian}
                readOnly={!_.get(props.fieldsEnabled, 'auctionAddress', true)}
                onChange={props.handleChange}
              />
              <FormGroup
                required
                name="addressGps"
                label={props.getTranslation('tab_location_label_address_gps')}
                helpText={
                  <div>
                    {props.getTranslation('tab_location_address_gps_help_text_title')}
                    <br />
                    {props.getTranslation('tab_location_address_gps_help_text_text')}
                  </div>
                }
                labelClassName="text-left"
                error={`${props.errors.auctionAddress_latitude || ''}${
                  props.errors.auctionAddress_longitude ? ', ' : ''
                }${props.errors.auctionAddress_longitude || ''}`}
                value={props.values.addressGps}
                readOnly={!_.get(props.fieldsEnabled, 'auctionAddress', true)}
                onChange={props.handleChange}
              />
            </div>
          </div>
          <div>
            <p className="f-size-12 w-weight-400 text-color-gray-2">{props.getTranslation('tab_location_help_text')}</p>
          </div>
          {!props.id && (
            <Row>
              <Col xs={12} className="mt-4 text-right">
                {!props.isSubmitting ? (
                  <Button type="submit">{props.getTranslation('tab_location_btn_save')}</Button>
                ) : (
                  <BasePreloader size={29} className="d-inline-block" />
                )}
              </Col>
            </Row>
          )}
        </Form>
      </div>

      {showMapModal && (
        <MapAddressModal
          isOpen={showMapModal}
          gps={props.values.addressGps}
          onSelect={handleSelectFromMap}
          onClose={() => setShowMapModal(false)}
        />
      )}
    </div>
  );
};

export default LocationSection;
