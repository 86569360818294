import React, { ChangeEvent, useEffect } from 'react';
import _ from 'lodash';
import { Col, Form, Row } from 'react-bootstrap';
import { FormikErrors, FormikValues } from 'formik';

import { useEnumApi } from '@api/enum';
import { AuctionFieldsEnabled } from '@types';
import { useAuctionsApi } from '@api/auctions';
import { useAuctionCategoriesApi } from '@api/auctionCategories';
import { BasePreloader, Button, FormGroup, Select } from '@components';
import ControlFeedback from '@components/ControlFeedback/ControlFeedback';

interface Props {
  id?: string;
  values: FormikValues;
  isSubmitting: boolean;
  errors: FormikErrors<FormikValues>;
  fieldsEnabled?: AuctionFieldsEnabled;
  auctionType: 'auction' | 'auction_public' | 'public_competition';
  getTranslation: (key: string) => string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(
      field: T_1
    ): T_1 extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
  };
}

const SettingsSection: React.FC<Props> = (props) => {
  const enumApi = useEnumApi();
  const auctionsApi = useAuctionsApi();
  const auctionCategoriesApi = useAuctionCategoriesApi();
  const { auctionAuthorizationsType } = props.values;

  const userIdentificationOptions = [
    { value: 'vs', label: 'Variabilní symbol' },
    { value: 'ss', label: 'Specifický symbol' },
  ];

  useEffect(() => {
    return () => {
      enumApi.cancelAllRequests();
      auctionsApi.cancelAllRequests();
      auctionCategoriesApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function checkAll() {
    if (_.get(props.fieldsEnabled, 'auctionAuthorizationsType', true)) {
      const userAuctionAuthorizationTypeArray = [
        'personally',
        'post',
        'formWithCertificate',
        'dataBoxWithCertificate',
        'dataBoxWithConvertDocument',
        'userAccountConvertDocument',
      ];
      props.setFieldValue('auctionAuthorizationsType', userAuctionAuthorizationTypeArray);
    }
  }

  function handleVariableSymbolType(value: string) {
    props.setFieldValue('auctionCautionBankAccount.variableSymbolType', value);
  }

  function handleSpecificSymbolType(value: string) {
    props.setFieldValue('auctionCautionBankAccount.specificSymbolType', value);
  }

  const isBankAccount = (): boolean => props.values.auctionCautionBankAccount.enabled;

  return (
    <div>
      <div className="pl-2">
        <h2 className="f-weight-300 f-size-25 mb-0">{props.getTranslation('tab_settings_security_title')}</h2>
        <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => props.handleSubmit(e)} className="mt-30">
          <div className="responsive-table-content">
            <div>
              <Form.Check
                custom
                type="checkbox"
                label={props.getTranslation('tab_settings_security_personally')}
                className="mt-checkbox mr-4 mb-3"
                id="personally"
                name="[auctionAuthorizationsType]"
                value="personally"
                disabled={!_.get(props.fieldsEnabled, 'auctionAuthorizationsType', true)}
                checked={auctionAuthorizationsType.includes('personally') || false}
                onChange={props.handleChange}
              />
              <Form.Check
                custom
                type="checkbox"
                label={props.getTranslation('tab_settings_security_post')}
                className="mt-checkbox mr-4 mb-3"
                id="post"
                value="post"
                name="[auctionAuthorizationsType]"
                checked={auctionAuthorizationsType.includes('post') || false}
                disabled={!_.get(props.fieldsEnabled, 'auctionAuthorizationsType', true)}
                onChange={props.handleChange}
              />
              <Form.Check
                custom
                type="checkbox"
                label={props.getTranslation('tab_settings_security_data_box_with_certificate')}
                className="mt-checkbox mr-4 mb-3"
                id="dataBoxWithCertificate"
                value="dataBoxWithCertificate"
                name="[auctionAuthorizationsType]"
                checked={auctionAuthorizationsType.includes('dataBoxWithCertificate') || false}
                disabled={!_.get(props.fieldsEnabled, 'auctionAuthorizationsType', true)}
                onChange={props.handleChange}
              />
              <Form.Check
                custom
                type="checkbox"
                label={props.getTranslation('tab_settings_security_form_with_certificate')}
                className="mt-checkbox mr-4 mb-3"
                id="formWithCertificate"
                value="formWithCertificate"
                name="[auctionAuthorizationsType]"
                checked={auctionAuthorizationsType.includes('formWithCertificate') || false}
                disabled={!_.get(props.fieldsEnabled, 'auctionAuthorizationsType', true)}
                onChange={props.handleChange}
              />
              <Form.Check
                custom
                type="checkbox"
                label={props.getTranslation('tab_settings_security_data_box_with_convert_document')}
                className="mt-checkbox mr-4 mb-3"
                id="dataBoxWithConvertDocument"
                value="dataBoxWithConvertDocument"
                name="[auctionAuthorizationsType]"
                checked={auctionAuthorizationsType.includes('dataBoxWithConvertDocument') || false}
                disabled={!_.get(props.fieldsEnabled, 'auctionAuthorizationsType', true)}
                onChange={props.handleChange}
              />
              <Form.Check
                custom
                type="checkbox"
                label={props.getTranslation('tab_settings_security_user_account_convert_document')}
                className="mt-checkbox mr-4 mb-3"
                id="userAccountConvertDocument"
                value="userAccountConvertDocument"
                name="[auctionAuthorizationsType]"
                checked={auctionAuthorizationsType.includes('userAccountConvertDocument') || false}
                disabled={!_.get(props.fieldsEnabled, 'auctionAuthorizationsType', true)}
                onChange={props.handleChange}
              />
              <button
                type="button"
                className="btn btn-link"
                disabled={!_.get(props.fieldsEnabled, 'auctionAuthorizationsType', true)}
                onClick={checkAll}
              >
                Vybrat vše
              </button>

              <h2 className="f-weight-300 f-size-25 mt-30">{props.getTranslation('tab_settings_caution_title')}</h2>

              <Form.Group className="mt-30">
                <Form.Check
                  custom
                  id="inCash"
                  type="checkbox"
                  value="auctionCautionCash"
                  name="auctionCautionsType.type"
                  className="mt-checkbox mr-4 mb-3"
                  checked={props.values.auctionCautionCash.enabled}
                  label={props.getTranslation('tab_settings_caution_is_cash')}
                  disabled={!_.get(props.fieldsEnabled, 'auctionCautionCash', true)}
                  onChange={(e) => props.setFieldValue('auctionCautionCash.enabled', e.target.checked)}
                />
                <Form.Check
                  custom
                  type="checkbox"
                  id="bankGuarantee"
                  name="auctionCautionsType.type"
                  className="mt-checkbox mr-4 mb-3"
                  value="auctionCautionBankGuarantee"
                  checked={props.values.auctionCautionBankGuarantee.enabled}
                  label={props.getTranslation('tab_settings_caution_is_bank_guarantee')}
                  disabled={!_.get(props.fieldsEnabled, 'auctionCautionBankGuarantee', true)}
                  onChange={(e) => props.setFieldValue('auctionCautionBankGuarantee.enabled', e.target.checked)}
                />
                <Form.Check
                  custom
                  type="checkbox"
                  id="onBankAccount"
                  name="auctionCautionsType.type"
                  value="auctionCautionBankAccount"
                  className="mt-checkbox mr-4 mb-3"
                  checked={props.values.auctionCautionBankAccount.enabled}
                  label={props.getTranslation('tab_settings_caution_on_bank_account')}
                  disabled={!_.get(props.fieldsEnabled, 'auctionCautionBankAccount', true)}
                  onChange={(e) => props.setFieldValue('auctionCautionBankAccount.enabled', e.target.checked)}
                />
              </Form.Group>
              <Form.Group className="f-inline-group">
                <Form.Label className="f-inline-label">
                  {props.getTranslation('tab_settings_caution_label_bank_account')} *
                </Form.Label>
                <div className="f-inline-control d-flex align-items-center mb-3 mb-md-0">
                  <div>
                    <FormGroup
                      required
                      controlOnly
                      inputRequired
                      type="text"
                      name="bankAccountNumber"
                      controlClassName="w-max-315"
                      value={props.values.auctionCautionBankAccount.bankAccountNumber || ''}
                      error={props.errors.auctionCautionBankAccount_bankAccountNumber as string}
                      readOnly={!isBankAccount() || !_.get(props.fieldsEnabled, 'auctionCautionBankAccount', true)}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        props.setFieldValue('auctionCautionBankAccount.bankAccountNumber', e.target.value)
                      }
                    />
                  </div>
                  <span className="f-size-12 w-weight-400 ml-3 mr-3">/</span>
                  <div>
                    <FormGroup
                      required
                      controlOnly
                      inputRequired
                      type="text"
                      name="bankAccountCode"
                      controlClassName="w-max-150"
                      value={props.values.auctionCautionBankAccount.bankAccountCode || ''}
                      error={props.errors.auctionCautionBankAccount_bankAccountCode as string}
                      hideErrorMessage={!!props.errors.auctionCautionBankAccount_bankAccountNumber}
                      readOnly={!isBankAccount() || !_.get(props.fieldsEnabled, 'auctionCautionBankAccount', true)}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        props.setFieldValue('auctionCautionBankAccount.bankAccountCode', e.target.value)
                      }
                    />
                  </div>
                </div>
              </Form.Group>

              <div>
                <Form.Group className="f-inline-group">
                  <Form.Label className="f-inline-label text-left text-nowrap">Identifikátor dražitele *</Form.Label>
                  <div className="f-inline-control">
                    <div style={{ maxWidth: 355 }} data-test-id="auctionType">
                      <Select
                        size="md"
                        options={userIdentificationOptions}
                        isInvalid={!!props.errors.auctionCautionBankAccount_buyerIdentifier}
                        readOnly={!isBankAccount() || !_.get(props.fieldsEnabled, 'auctionCautionBankAccount', true)}
                        value={
                          userIdentificationOptions.find(
                            (i) => i.value === props.values.auctionCautionBankAccount.buyerIdentifier
                          ) || null
                        }
                        onChange={(val) => {
                          props.setFieldValue('auctionCautionBankAccount.buyerIdentifier', val?.value || 'vs');
                          props.setFieldValue('auctionCautionBankAccount.variableSymbolType', '');
                          props.setFieldValue('auctionCautionBankAccount.specificSymbolType', '');
                        }}
                      />
                      {!!props.errors.auctionType && (
                        <ControlFeedback type="invalid">{props.errors.buyerIdentifier as string}</ControlFeedback>
                      )}
                    </div>
                  </div>
                </Form.Group>
              </div>

              <Form.Group className="f-inline-group">
                <Form.Label className="f-inline-label mb-0">
                  {props.getTranslation('tab_settings_caution_label_variable_symbol')} *
                </Form.Label>
                <div className="f-inline-control d-flex align-items-center settings-group">
                  {props.values.auctionCautionBankAccount.buyerIdentifier === 'vs' && (
                    <Form.Check
                      custom
                      disabled={!isBankAccount() || !_.get(props.fieldsEnabled, 'auctionCautionBankAccount', true)}
                      type="radio"
                      label={props.getTranslation('tab_settings_caution_label_variable_symbol_type_rico')}
                      className="mr-4 radio-point"
                      id="variableSymbolTypeRcico"
                      name="variableSymbolType"
                      checked={props.values.auctionCautionBankAccount.variableSymbolType === 'rc_ico'}
                      onChange={() => handleVariableSymbolType('rc_ico')}
                    />
                  )}

                  {props.values.auctionCautionBankAccount.buyerIdentifier === 'ss' && (
                    <Form.Check
                      custom
                      disabled={!isBankAccount() || !_.get(props.fieldsEnabled, 'auctionCautionBankAccount', true)}
                      type="radio"
                      label={props.getTranslation('tab_settings_caution_specific_symbol_auction_generated')}
                      className="radio-point mr-4"
                      id="variable_generated_auction"
                      name="variableSymbolType"
                      checked={props.values.auctionCautionBankAccount.variableSymbolType === 'generated_auction'}
                      onChange={() => handleVariableSymbolType('generated_auction')}
                    />
                  )}

                  <>
                    <Form.Check
                      custom
                      disabled={!isBankAccount() || !_.get(props.fieldsEnabled, 'auctionCautionBankAccount', true)}
                      type="radio"
                      label={props.getTranslation('tab_settings_caution_label_variable_symbol_type_custom')}
                      className="mr-4 radio-point"
                      id="variableSymbolTypeCustom"
                      name="variableSymbolType"
                      checked={props.values.auctionCautionBankAccount.variableSymbolType === 'own'}
                      onChange={() => handleVariableSymbolType('own')}
                    />
                    <FormGroup
                      type="text"
                      name="customVariableSymbol"
                      className="mb-0 w-min-200 mt-4 mb-3 mt-md-0 mb-md-0"
                      value={props.values.auctionCautionBankAccount.variableSymbol || ''}
                      error={props.errors.auctionCautionBankAccount_variableSymbol as string}
                      readOnly={
                        !isBankAccount() ||
                        props.values.auctionCautionBankAccount.variableSymbolType !== 'own' ||
                        !_.get(props.fieldsEnabled, 'auctionCautionBankAccount', true)
                      }
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        props.setFieldValue('auctionCautionBankAccount.variableSymbol', e.target.value)
                      }
                    />
                  </>

                  {props.values.auctionCautionBankAccount.buyerIdentifier === 'vs' && (
                    <Form.Check
                      custom
                      disabled={!isBankAccount() || !_.get(props.fieldsEnabled, 'auctionCautionBankAccount', true)}
                      type="radio"
                      label={props.getTranslation('tab_settings_caution_specific_symbol_generated')}
                      className="radio-point mr-4 "
                      id="variable_generated_user"
                      name="variableSymbolType"
                      checked={props.values.auctionCautionBankAccount.variableSymbolType === 'generated_user'}
                      onChange={() => handleVariableSymbolType('generated_user')}
                    />
                  )}
                  {!!props.errors.auctionCautionBankAccount_variableSymbolType && (
                    <ControlFeedback type="invalid" style={{ marginTop: 70 }}>
                      {props.errors.auctionCautionBankAccount_variableSymbolType as string}
                    </ControlFeedback>
                  )}
                </div>
              </Form.Group>
              <Form.Group className="f-inline-group">
                <Form.Label className="f-inline-label mb-0">
                  {props.getTranslation('tab_settings_caution_label_specific_symbol')} *
                </Form.Label>
                <div className="f-inline-control d-flex align-items-center settings-group">
                  {props.values.auctionCautionBankAccount.buyerIdentifier === 'ss' && (
                    <Form.Check
                      custom
                      disabled={!isBankAccount() || !_.get(props.fieldsEnabled, 'auctionCautionBankAccount', true)}
                      type="radio"
                      label={props.getTranslation('tab_settings_caution_label_specific_symbol_type')}
                      className="radio-point mr-4"
                      id="rcico"
                      name="specificSymbolType"
                      checked={props.values.auctionCautionBankAccount.specificSymbolType === 'rc_ico'}
                      onChange={() => handleSpecificSymbolType('rc_ico')}
                    />
                  )}

                  {props.values.auctionCautionBankAccount.buyerIdentifier === 'vs' && (
                    <Form.Check
                      custom
                      disabled={!isBankAccount() || !_.get(props.fieldsEnabled, 'auctionCautionBankAccount', true)}
                      type="radio"
                      label={props.getTranslation('tab_settings_caution_specific_symbol_auction_generated')}
                      className="radio-point mr-4"
                      id="generated_auction"
                      name="specificSymbolType"
                      checked={props.values.auctionCautionBankAccount.specificSymbolType === 'generated_auction'}
                      onChange={() => handleSpecificSymbolType('generated_auction')}
                    />
                  )}

                  <>
                    <Form.Check
                      custom
                      disabled={!isBankAccount() || !_.get(props.fieldsEnabled, 'auctionCautionBankAccount', true)}
                      type="radio"
                      label={props.getTranslation('tab_settings_caution_label_specific_symbol_type_custom')}
                      className="mr-4 radio-point"
                      id="custom"
                      name="specificSymbolType"
                      checked={props.values.auctionCautionBankAccount.specificSymbolType === 'own'}
                      onChange={() => handleSpecificSymbolType('own')}
                    />
                    <FormGroup
                      readOnly={
                        !isBankAccount() ||
                        props.values.auctionCautionBankAccount.specificSymbolType !== 'own' ||
                        !_.get(props.fieldsEnabled, 'auctionCautionBankAccount', true)
                      }
                      type="text"
                      name="customSpecificSymbol"
                      className="mb-0 w-min-200 mt-4 mb-0 mt-md-0 mt-mb-0"
                      value={props.values.auctionCautionBankAccount.specificSymbol || ''}
                      error={props.errors.auctionCautionBankAccount_specificSymbol as string}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        props.setFieldValue('auctionCautionBankAccount.specificSymbol', e.target.value)
                      }
                    />
                  </>

                  {props.values.auctionCautionBankAccount.buyerIdentifier === 'ss' && (
                    <Form.Check
                      custom
                      disabled={!isBankAccount() || !_.get(props.fieldsEnabled, 'auctionCautionBankAccount', true)}
                      type="radio"
                      label={props.getTranslation('tab_settings_caution_specific_symbol_generated')}
                      className="radio-point mr-4"
                      id="generated_user"
                      name="specificSymbolType"
                      checked={props.values.auctionCautionBankAccount.specificSymbolType === 'generated_user'}
                      onChange={() => handleSpecificSymbolType('generated_user')}
                    />
                  )}

                  {!!props.errors.auctionCautionBankAccount_specificSymbolType && (
                    <ControlFeedback type="invalid" style={{ marginTop: 70 }}>
                      {props.errors.auctionCautionBankAccount_specificSymbolType as string}
                    </ControlFeedback>
                  )}
                </div>
              </Form.Group>
            </div>
          </div>
          {!props.id && (
            <Row>
              <Col xs={12} className="mt-4 text-right">
                {!props.isSubmitting ? (
                  <Button type="submit">{props.getTranslation('tab_settings_btn_save')}</Button>
                ) : (
                  <BasePreloader size={29} className="d-inline-block" />
                )}
              </Col>
            </Row>
          )}
        </Form>
      </div>
    </div>
  );
};

export default SettingsSection;
