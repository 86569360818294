import React, { useEffect } from 'react';

import * as routes from '@routes';
import { AuctionPrint, BaseFrontPage, FrontAuctionDetail } from '@components';
import {
  FrontSidebarMenu,
  FrontSidebarStatistics,
  FrontSidebarNotifications,
  FrontSidebarBanners,
} from '@components/FrontSidebarBox';
import { AuctionTypeEnum } from '@components/FrontAuctionDetail/FrontAuctionDetail';

type Props = {
  type: keyof typeof AuctionTypeEnum;
};

const AuctionDetailPage: React.FC<Props> = (props) => {
  useEffect(() => {
    document.body.classList.add('print-page');
    return () => {
      document.body.classList.remove('print-page');
    };
  }, []);

  return (
    <BaseFrontPage
      page={routes.front.AUCTION_DETAIL}
      printContent={<AuctionPrint type={props.type} />}
      sidebarItems={[
        <FrontSidebarMenu title="Dražby a veřejné soutěže" />,
        <FrontSidebarNotifications title="Upozornění na Váš e-mail" />,
        <FrontSidebarStatistics title="Exdražby v číslech" />,
        <FrontSidebarBanners />,
      ]}
    >
      <FrontAuctionDetail type={props.type} />
    </BaseFrontPage>
  );
};

export default AuctionDetailPage;
