import React from 'react';
import { BaseObjectType } from '@types';

import * as routes from '@routes';
import { usePageStore } from '@stores';
import { getCurrencyFormat } from '@helpers/formats';
import AuctionImage from '@components/AuctionImage/AuctionImage';

import auctionNoImage from '@assets/images/auction-no-image.svg';

interface Props {
  navigate: any;
  id: number;
  image: string | null;
  title: string;
  category: string;
  parentCategory: string;
  number: string;
  region: string;
  city: string;
  deposit: string;
  auctionType?: string;
  typeTranslation?: string;
  translations?: BaseObjectType;
}

const MapPopup: React.FC<Props> = (props) => {
  const pageState = usePageStore();
  return (
    <div className="map-popup-content">
      <div
        className="popup-image-content"
        onClick={() =>
          props.navigate(
            pageState.getPagePath(
              props.auctionType === 'auction' ? routes.front.AUCTION_DETAIL : routes.front.AUCTION_PUBLIC_DETAIL,
              {
                ':id': props.id,
              }
            )
          )
        }
      >
        {props.image ? (
          <AuctionImage style={{ width: '100%', cursor: 'pointer' }} hash={props.image} size="auction.crop" />
        ) : (
          <img style={{ cursor: 'pointer' }} src={auctionNoImage} alt="auction" />
        )}
      </div>
      <div className="map-popup-title">{props.title}</div>
      <div className="map-popup-text">
        {props.typeTranslation && (
          <>
            <strong>Typ:</strong> {props.typeTranslation}
            <br />
          </>
        )}
        <strong>
          {props.parentCategory ? `${props.parentCategory} - ` : ''}
          {props.category}:
        </strong>{' '}
        čj: {props.number}
        <br />
        <strong>Kraj:</strong> {props.region}, {props.city}
        <br />
        <strong>{props.translations?.auction_security_pay || 'auction_security_pay'}:</strong>
        {parseFloat(props.deposit) || 0 ? ` ${getCurrencyFormat(props.deposit)}` : ` Není vyžadována`}
      </div>
      <div
        className="map-popup-link"
        style={{ cursor: 'pointer' }}
        onClick={() => {
          props.navigate(
            pageState.getPagePath(
              props.auctionType === 'auction' ? routes.front.AUCTION_DETAIL : routes.front.AUCTION_PUBLIC_DETAIL,
              {
                ':id': props.id,
              }
            )
          );
        }}
      >
        Více informací
      </div>
    </div>
  );
};

export default MapPopup;
